// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = Record<string, any>;

export enum SubscriptionStatus {
	REQUESTED = 'REQUESTED',
	PENDING = 'PENDING', // only for non auto funds
	KYC_VALIDATION = 'KYC_VALIDATION', // only for non auto funds
	CONTRACT_SENT_TO_CLIENT = 'CONTRACT_SENT_TO_CLIENT', // only for non auto funds
	SIGNED = 'SIGNED',
	PARTNER_VALIDATION = 'PARTNER_VALIDATION',
	PARTNER_TREATMENT = 'PARTNER_TREATMENT', // only for transfer
	PAYMENT = 'PAYMENT',
	REGISTERED = 'REGISTERED',
	COMPLETED = 'COMPLETED',
	TERMINATED = 'TERMINATED',
}

export enum ProductType {
	ART = 'ART',
	CASH = 'CASH',
	CROWDFUNDING = 'CROWDFUNDING',
	INVEST = 'INVEST',
	PE = 'PE',
	SCPI = 'SCPI',
	GIRARDIN = 'GIRARDIN',
	AV_LUX = 'AV_LUX',
	CRYPTO = 'CRYPTO',
}

export enum LegalEntity {
	PHYSICAL = 'PHYSICAL',
	MORAL = 'MORAL',
}

export type BOContext = 'client' | 'subscription' | 'deal' | 'transfer' | 'blocking-instance' | 'propale' | 'meeting';
