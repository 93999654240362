import { FC } from 'react';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

type TableGraphProps = {
	titleKey?: string;
	titleValue?: string;
	data: { id: string; value: string }[];
};

const TableGraph: FC<TableGraphProps> = ({ titleKey, titleValue, data }) => (
	<Box>
		<TableContainer bg="white" w="500px">
			<Table variant="simple" size="sm">
				{titleKey && titleValue && (
					<Thead style={{ backgroundColor: '#E7E7E7' }}>
						<Tr>
							<Th>{titleKey}</Th>
							<Th isNumeric>{titleValue}</Th>
						</Tr>
					</Thead>
				)}
				<Tbody>
					{data.map(({ id, value }) => (
						<Tr key={id}>
							<Td>{id}</Td>
							<Td isNumeric>{value}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	</Box>
);

export default TableGraph;
