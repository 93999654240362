import { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, GridItem, HStack, Radio, RadioGroup } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { CrossAssetBacktestBody } from 'services/cpm/cross-asset-backtest';

type CustomRiskProps = {
	riskKey: keyof CrossAssetBacktestBody;
};

const CustomRisk: FC<CustomRiskProps> = ({ riskKey }) => {
	const { control, setValue } = useFormContext<CrossAssetBacktestBody>();

	const [isCustomRisk, setIsCustomRisk] = useState<boolean>(false);

	const handleCustomRisk = useCallback(
		(v: boolean) => {
			setIsCustomRisk(v);
			setValue(riskKey, v ? 5 : undefined);
		},
		[riskKey, setValue],
	);

	return (
		<>
			<FormControl>
				<FormLabel size="sm">Risque custom ?</FormLabel>
				<RadioGroup onChange={(v) => handleCustomRisk(v === 'true')} value={isCustomRisk ? 'true' : 'false'}>
					<HStack>
						<Radio value="false">Non</Radio>
						<Radio value="true">Oui</Radio>
					</HStack>
				</RadioGroup>
			</FormControl>
			{isCustomRisk && (
				<>
					<GridItem />
					<FormControl>
						<FormLabel size="sm">Risque</FormLabel>
						<Controller
							name={riskKey}
							control={control}
							render={({ field }) => (
								<NumberInputWithStepper {...field} value={`${field.value}`} step={0.1} min={1} max={10} />
							)}
						/>
					</FormControl>
				</>
			)}
		</>
	);
};

export default CustomRisk;
