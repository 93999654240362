import { FC, useCallback, useMemo } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, Skeleton, Switch, VStack, Wrap } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import FilterPopovers, { FILTER_POPOVERS } from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import DealsTable, { dealTableColumns } from 'components/tables/DealsTable';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import useThemedToast from 'hooks/useThemedToast';
import { AllDeal, useClearDealsCacheMutation, useGetDealsQuery } from 'services/deal';
import { useGetStatusesQuery } from 'services/ops/status';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BOContext, SubscriptionStatus } from 'types/global.type';
import { isCallDue } from 'utils/dealInfo';
import { toCSV } from 'utils/functions';

import useDealFilters, { DealProductOptions, filterDealproductType } from './utils';

//
// FILTERING & EXPORT
//

type DealFilters = {
	searchBy: SearchBy;
	input: string;
	productFilter: DealProductOptions[];
	clientTypeFilter: string[];
	statusFilter: SubscriptionStatus[];
	partnerFilter: string[];
	opsFilter: string[];
	includeDealsWithBI: boolean;
	onlyOverdue: boolean;
	onlyCallToDo: boolean;
	productTypeStatuses: Record<string, StatusLabel> | undefined;
};

const filterDealWithOptions = (deals: AllDeal[], f: DealFilters) =>
	deals
		// inputs
		.filter((s) => searchByFunc(s, f.searchBy, f.input))
		// product type
		.filter((s) => filterDealproductType(s, f.productFilter))
		// client type
		.filter((s) => {
			if (f.clientTypeFilter.length === 0) return true;
			return (
				(f.clientTypeFilter.includes('isBlack') && s.user.isBlack) ||
				(f.clientTypeFilter.includes('isPhoenix') && s.user.isPhoenix) ||
				(f.clientTypeFilter.includes('isCorporate') && s.user.isCorporate)
			);
		})
		// status
		.filter((s) => (f.statusFilter?.length === 0 ? true : f.statusFilter?.includes(s.status as SubscriptionStatus)))
		// partner
		.filter((s) => (f.partnerFilter.length === 0 ? true : f.partnerFilter.includes(s.partner)))
		// assigned ops
		.filter((s) => (f.opsFilter.length === 0 ? true : f.opsFilter.includes(s.opsProperties?.assignedOpsEmail ?? '')))
		// blocking instance
		.filter((s) => (!f.includeDealsWithBI ? !s.hasBlockingInstance : true))
		// overdue
		.filter((s) => (f.onlyOverdue ? isDealOverdue(s, f.productTypeStatuses) : true))
		// call to do
		.filter((s) => (f.onlyCallToDo ? isCallDue(s) : true));

const buildCsv = (deals: AllDeal[], f: DealFilters) => {
	const filtered = filterDealWithOptions(deals, f);
	const headerKeys = Object.keys(dealTableColumns);
	const header = headerKeys.map((key) => dealTableColumns[key as keyof typeof dealTableColumns].name);
	const rows = filtered.map((d) =>
		headerKeys.map((key) => dealTableColumns[key as keyof typeof dealTableColumns].get(d)),
	);
	return toCSV([header, ...rows]);
};

//
// COMPONENT
//

type DealListProps = {
	context: Extract<BOContext, 'client' | 'subscription' | 'deal' | 'blocking-instance'>;
	selectedDeal?: AllDeal; // for display purpose
	onClick?: (c: AllDeal) => void;
	pageSize?: number;
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
};

const DealList: FC<DealListProps> = ({ selectedDeal, onClick, pageSize, customSearchBy, customInput, context }) => {
	const toast = useThemedToast();

	const {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		clientTypeFilter,
		setClientTypeFilter,
		opsFilter,
		setOpsFilter,
		onlyOverdue,
		setOnlyOverdue,
		onlyCallToDo,
		setOnlyCallToDo,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		input,
		setInput,
		searchBy,
		setSearchBy,
		productFilter,
		setProductFilter,
		resetFilters,
	} = useDealFilters(context, customSearchBy, customInput);

	const [clearDealsCache] = useClearDealsCacheMutation();
	const handleClearDealsCache = useCallback(() => clearDealsCache().unwrap(), [clearDealsCache]);

	const { data: deals, isFetching: isSubsFetching } = useGetDealsQuery(
		{ searchBy: customSearchBy ?? 'email', input: customInput ?? '' },
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);
	const { data: productTypeStatuses } = useGetStatusesQuery();
	const partnerOptions = useMemo(() => [...new Set(deals?.map((d) => d.partner))].sort(), [deals]);

	const opsOptions = useMemo(
		() => [...new Set(deals?.map((d) => d.opsProperties?.assignedOpsEmail ?? ''))].filter((o) => o.length > 0).sort(),
		[deals],
	);

	const allFilters = useMemo(
		() => ({
			partnerFilter,
			statusFilter,
			clientTypeFilter,
			opsFilter,
			onlyOverdue,
			onlyCallToDo,
			includeDealsWithBI,
			productFilter,
			input,
			searchBy,
			productTypeStatuses,
		}),
		[partnerFilter, statusFilter, clientTypeFilter, opsFilter, onlyOverdue, onlyCallToDo, includeDealsWithBI, productFilter, input, searchBy, productTypeStatuses], // prettier-ignore
	);

	const downloadCsv = useCallback(
		() => fileDownload(buildCsv(deals ?? [], allFilters), 'extract', 'text/csv'),
		[deals, allFilters],
	);

	return (
		<VStack w="100%" align="start">
			{(context === 'deal' || context === 'blocking-instance') && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<Wrap>
							<SearchByFilter
								size="sm"
								search={input}
								onChangeSearch={setInput}
								searchBy={searchBy}
								onChangeSearchBy={setSearchBy}
								isFetching={isSubsFetching}
								onClearCache={handleClearDealsCache}
							/>
							<FilterPopovers
								size="sm"
								components={[
									{
										component: FILTER_POPOVERS.BASIC,
										title: 'Produit',
										componentProps: {
											value: productFilter,
											onChange: (v: string[]) => setProductFilter(v as DealProductOptions[]),
											options: Object.values(DealProductOptions).filter((o) => o !== DealProductOptions.TRANSFER),
										},
									},
									{
										title: 'Statut',
										componentProps: {
											value: statusFilter,
											onChange: (v: string[]) => setStatusFilter(v as SubscriptionStatus[]),
											options: Object.values(SubscriptionStatus).filter(
												(s) => s !== SubscriptionStatus.PARTNER_TREATMENT,
											),
										},
									},
									{
										title: 'Client type',
										componentProps: {
											onChange: setClientTypeFilter,
											options: ['isBlack', 'isPhoenix', 'isCorporate'],
											value: clientTypeFilter,
										},
									},
									{
										component: FILTER_POPOVERS.PARTNER,
										title: 'Partenaire',
										componentProps: {
											onChange: setPartnerFilter,
											options: partnerOptions,
											value: partnerFilter,
										},
									},
									{
										component: FILTER_POPOVERS.OPS,
										title: 'OPS',
										componentProps: {
											onChange: setOpsFilter,
											options: opsOptions,
											value: opsFilter,
										},
									},
								]}
							/>
							<Button
								size="sm"
								_hover={{ cursor: 'auto' }}
								rightIcon={
									<Switch
										size="sm"
										isChecked={onlyOverdue}
										onChange={(event) => setOnlyOverdue(event.target.checked)}
									/>
								}
							>
								Overdue
							</Button>
							<Button
								size="sm"
								_hover={{ cursor: 'auto' }}
								rightIcon={
									<Switch
										size="sm"
										isChecked={includeDealsWithBI}
										onChange={(event) => setIncludeDealsWithBI(event.target.checked)}
									/>
								}
							>
								Instances
							</Button>
							<Button
								size="sm"
								_hover={{ cursor: 'auto' }}
								rightIcon={
									<Switch
										size="sm"
										isChecked={onlyCallToDo}
										onChange={(event) => setOnlyCallToDo(event.target.checked)}
									/>
								}
							>
								Call ToDo
							</Button>
						</Wrap>
					</HStack>

					<Menu closeOnSelect={false}>
						<MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem
								onClick={() => {
									toast({ title: 'Filtres réinitialisés', status: 'info' });
									resetFilters();
								}}
							>
								Réinitialiser les filtres
							</MenuItem>
							<MenuItem onClick={() => downloadCsv()}>Exporter la vue</MenuItem>
						</MenuList>
					</Menu>
				</HStack>
			)}

			<Skeleton isLoaded={!isSubsFetching} w="100%">
				<DealsTable
					context={context}
					productTypeStatuses={productTypeStatuses}
					deals={filterDealWithOptions(deals ?? [], allFilters)}
					onClick={onClick}
					selectedDeal={selectedDeal}
					pageSize={pageSize ?? 50}
				/>
			</Skeleton>
		</VStack>
	);
};

export default DealList;
