import { useState } from 'react';
import { HStack, Input, Select } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import BasicCard from 'components/BasicCard';
import DatePicker from 'components/DatePicker';
import NumberInputWithStepper from 'components/NumberInput';
import useThemedToast from 'hooks/useThemedToast';
import {
	useLazyGetInvestAffiliationCashbackQuery,
	useLazyGetInvestCashbackQuery,
	useLazyGetScpiCashbackQuery,
	useLazyGetScpiPeCashbackQuery,
} from 'services/ops/cashback';
import { isNotNone } from 'utils/functions';

const months = [
	'Janvier',
	'Février',
	'Mars',
	'Avril',
	'Mai',
	'Juin',
	'Juillet',
	'Août',
	'Septembre',
	'Octobre',
	'Novembre',
	'Décembre',
];

const MonthSelect = ({ month, setMonth }: { month: string; setMonth: (m: string) => void }) => (
	<Select placeholder="Choisissez un mois" value={month} onChange={(e) => setMonth(e.target.value)}>
		{months.map((m) => (
			<option key={m} value={m}>
				{m}
			</option>
		))}
	</Select>
);

const getThreeMonthsAgo = () => {
	const date = new Date();
	const ret = date.getMonth() - 3;
	return ret < 0 ? ret + 12 : ret;
};

const LegacyCashbackScpiPeCard = () => {
	const toast = useThemedToast();

	const [downloadPeScpiCashback, { isFetching: isPeScpiFetching }] = useLazyGetScpiPeCashbackQuery();

	const [month, setMonth] = useState(months[getThreeMonthsAgo()]);
	const [year, setYear] = useState(new Date().getFullYear().toString());

	return (
		<BasicCard
			title="Parrainage SCPI/PE (LEGACY)"
			description="Génération automatisée d'un CSV contenant les parrainage SCPI/PE"
			buttonText="Télécharger"
			isLoading={isPeScpiFetching}
			onClick={() =>
				downloadPeScpiCashback({ month, year })
					.unwrap()
					.then((res) => fileDownload(res, 'extract', 'text/csv'))
					.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }))
			}
			body={
				<HStack>
					<MonthSelect month={month} setMonth={setMonth} />
					<NumberInputWithStepper value={year} onChange={setYear} max={new Date().getFullYear()} min={2021} />
				</HStack>
			}
		/>
	);
};

const CashbackInvestCard = () => {
	const toast = useThemedToast();

	const [downloadInvestCashback, { isFetching: isInvestFetching }] = useLazyGetInvestAffiliationCashbackQuery();

	const [dateComputeStart, setDateComputeStart] = useState(new Date());
	const [dateComputeEnd, setDateComputeEnd] = useState(new Date());

	return (
		<BasicCard
			title="Affiliation INVEST"
			description="Génération automatisée d'un CSV contenant les affiliations partenaires INVEST"
			buttonText="Télécharger"
			isLoading={isInvestFetching}
			onClick={() =>
				downloadInvestCashback({
					dateComputeStart: dateComputeStart.toISOString(),
					dateComputeEnd: dateComputeEnd.toISOString(),
				})
					.unwrap()
					.then((res) => fileDownload(res, 'extract', 'text/csv'))
					.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }))
			}
			body={
				<HStack>
					<DatePicker
						width="150px"
						selected={dateComputeStart}
						onChange={(d) => isNotNone(d) && setDateComputeStart(d)}
					/>
					<DatePicker width="150px" selected={dateComputeEnd} onChange={(d) => isNotNone(d) && setDateComputeEnd(d)} />
				</HStack>
			}
		/>
	);
};

const LegacyCashbackInvestCard = () => {
	const [downloadInvestCashback, { isFetching: isInvestFetching }] = useLazyGetInvestCashbackQuery();
	const toast = useThemedToast();
	const [dateComputeStart, setDateComputeStart] = useState('');
	const [dateComputeEnd, setDateComputeEnd] = useState('');

	return (
		<BasicCard
			title="Parrainage INVEST (LEGACY)"
			description="Génération automatisée d'un CSV contenant les remises INVEST"
			buttonText="Télécharger"
			isLoading={isInvestFetching}
			onClick={() =>
				downloadInvestCashback({ dateComputeStart, dateComputeEnd })
					.unwrap()
					.then((res) => fileDownload(res, 'extract', 'text/csv'))
					.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }))
			}
			body={
				<HStack>
					<Input type="date" value={dateComputeStart} onChange={(e) => setDateComputeStart(e.target.value)} />
					<Input type="date" value={dateComputeEnd} onChange={(e) => setDateComputeEnd(e.target.value)} />
				</HStack>
			}
		/>
	);
};

const CashbackScpiCard = () => {
	const toast = useThemedToast();

	const [downloadScpiCashback, { isFetching: isScpiFetching }] = useLazyGetScpiCashbackQuery();

	const [month, setMonth] = useState(months[getThreeMonthsAgo()]);
	const [year, setYear] = useState(new Date().getFullYear().toString());

	return (
		<BasicCard
			title="Remise SCPI"
			description="Génération automatisée d'un CSV contenant les remises SCPI"
			buttonText="Télécharger"
			isLoading={isScpiFetching}
			onClick={() =>
				downloadScpiCashback({ month, year })
					.unwrap()
					.then((res) => fileDownload(res, 'extract', 'text/csv'))
					.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }))
			}
			body={
				<HStack>
					<MonthSelect month={month} setMonth={setMonth} />
					<NumberInputWithStepper value={year} onChange={setYear} max={new Date().getFullYear()} min={2021} />
				</HStack>
			}
		/>
	);
};

export { CashbackInvestCard, CashbackScpiCard, LegacyCashbackInvestCard, LegacyCashbackScpiPeCard };
