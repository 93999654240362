import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
	Button,
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Switch,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import FilterPopovers from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import BlockingInstancesTable, { blockingInstanceTableColumns } from 'components/tables/BlockingInstanceTable';
import { isBIOverdue, isBIOverdueToCall } from 'features/ChangeOverdueDate';
import useThemedToast from 'hooks/useThemedToast';
import BlockingInstanceCreate, { Partner } from 'pages/ops/super/blocking-instance/BlockingInstanceCreate';
import { BlockingInstanceWithKyc, useGetBlockingInstancesQuery } from 'services/ops/blocking-instance';
import { BlockingInstanceLifecycle, BlockingInstanceStatus } from 'types/blocking-instance.type';
import { BOContext } from 'types/global.type';
import { isNotNone, toCSV } from 'utils/functions';

import useBlockingInstanceFilters from './utils';

//
// FILTERING & EXPORT
//

type BlockingInstanceFilters = {
	searchBy: SearchBy;
	input: string;
	statusFilter: BlockingInstanceStatus[];
	partnerFilter: string[];
	lifecycleFilter: BlockingInstanceLifecycle[];
	onlyOverdue: boolean;
	toBeCalled: boolean;
};

const filterBlockingInstanceWithOptions = (bi: BlockingInstanceWithKyc[], f: BlockingInstanceFilters) =>
	bi
		.filter((s) => searchByFunc(s, f.searchBy, f.input))
		.filter((s) => f.statusFilter.includes(s.status))
		.filter((s) => f.partnerFilter.includes(s.partner))
		.filter((s) => f.lifecycleFilter.includes(s.lifecycle))
		.filter((s) => (f.onlyOverdue ? isBIOverdue(s) : true))
		.filter((s) => (f.toBeCalled ? isBIOverdueToCall(s) && s.status === BlockingInstanceStatus.SENT_TO_CLIENT : true));

const buildCsv = (bi: BlockingInstanceWithKyc[], f: BlockingInstanceFilters) => {
	const filtered = filterBlockingInstanceWithOptions(bi, f);
	const headerKeys = Object.keys(blockingInstanceTableColumns);
	const header = headerKeys.map(
		(key) => blockingInstanceTableColumns[key as keyof typeof blockingInstanceTableColumns].name,
	);
	const rows = filtered.map((d) =>
		headerKeys.map((key) => blockingInstanceTableColumns[key as keyof typeof blockingInstanceTableColumns].get(d)),
	);
	return toCSV([header, ...rows]);
};

//
// COMPONENT
//

export type BlockingInstanceListProps = {
	context: Extract<BOContext, 'client' | 'blocking-instance'>;
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
};

const BlockingInstanceList: FC<BlockingInstanceListProps> = ({ context, customSearchBy, customInput }) => {
	const location = useLocation();
	const toast = useThemedToast();
	const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();

	const {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		lifecycleFilter,
		setLifecycleFilter,
		onlyOverdue,
		setOnlyOverdue,
		searchBy,
		setSearchBy,
		input,
		setInput,
		toBeCalled,
		setToBeCalled,
		resetFilters,
	} = useBlockingInstanceFilters(context, customSearchBy, customInput);

	const { data: bis, isFetching: isBisFetching } = useGetBlockingInstancesQuery(
		{ searchBy: customSearchBy ?? 'email', input: customInput ?? '' },
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);

	const [selectedBI, setSelectedBI] = useState<BlockingInstanceWithKyc | null>(null);

	// when creating a blocking instance from a souscription page
	useEffect(() => {
		if (isNotNone(location.state?.deal)) onOpenCreate();
	}, [location.state?.deal, onOpenCreate]);

	const handleBlockingInstanceMatch = useCallback(
		(instance: BlockingInstanceWithKyc) => {
			setSelectedBI(instance);
			onOpenCreate();
		},
		[onOpenCreate],
	);

	const handleCreateBlockingInstance = useCallback(() => {
		setSelectedBI(null);
		onOpenCreate();
	}, [onOpenCreate]);

	const handleOnCloseCreate = useCallback(() => {
		setSelectedBI(null);
		onCloseCreate();
	}, [onCloseCreate]);

	const allFilters = useMemo(
		() => ({
			partnerFilter,
			statusFilter,
			lifecycleFilter,
			onlyOverdue,
			input,
			searchBy,
			toBeCalled,
		}),
		[partnerFilter, statusFilter, lifecycleFilter, onlyOverdue, input, searchBy, toBeCalled],
	);

	const downloadCsv = useCallback(
		() => fileDownload(buildCsv(bis ?? [], allFilters), 'extract', 'text/csv'),
		[bis, allFilters],
	);

	return (
		<VStack w="100%" align="start">
			{context === 'blocking-instance' && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<SearchByFilter
							size="sm"
							search={input}
							onChangeSearch={setInput}
							onChangeSearchBy={setSearchBy}
							searchBy={searchBy}
						/>
						<FilterPopovers
							size="sm"
							components={[
								{
									title: 'Statut',
									componentProps: {
										value: statusFilter,
										onChange: (v: string[]) => setStatusFilter(v as BlockingInstanceStatus[]),
										options: Object.values(BlockingInstanceStatus),
									},
								},
								{
									title: 'Lifecycle',
									componentProps: {
										value: lifecycleFilter,
										onChange: (v: string[]) => setLifecycleFilter(v as BlockingInstanceLifecycle[]),
										options: Object.values(BlockingInstanceLifecycle),
									},
								},
								{
									title: 'Partenaire',
									componentProps: {
										value: partnerFilter,
										onChange: setPartnerFilter,
										options: Object.values(Partner),
									},
								},
							]}
						/>
						<Button
							size="sm"
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch onChange={(event) => setOnlyOverdue(event.target.checked)} isChecked={onlyOverdue} />}
						>
							Overdue
						</Button>
						<Button
							size="sm"
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch onChange={(event) => setToBeCalled(event.target.checked)} isChecked={toBeCalled} />}
						>
							Call to do
						</Button>

						<Button size="sm" colorScheme="blue" onClick={handleCreateBlockingInstance} leftIcon={<AddIcon />}>
							Créer
						</Button>
					</HStack>

					<Menu closeOnSelect={false}>
						<MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem
								onClick={() => {
									toast({ title: 'Filtres réinitialisés', status: 'info' });
									resetFilters();
								}}
							>
								Réinitialiser les filtres
							</MenuItem>
							<MenuItem onClick={() => downloadCsv()}>Exporter la vue</MenuItem>
						</MenuList>
					</Menu>
				</HStack>
			)}

			<Skeleton isLoaded={!isBisFetching} w="100%">
				<BlockingInstancesTable
					context={context}
					blockingInstances={filterBlockingInstanceWithOptions(bis ?? [], allFilters)}
					onClick={handleBlockingInstanceMatch}
					selectedBlockingInstances={selectedBI ?? undefined}
					pageSize={50}
				/>
			</Skeleton>

			{isOpenCreate && (
				<BlockingInstanceCreate
					existingDeal={location.state?.deal}
					existingBlockingInstance={selectedBI ?? undefined}
					onClose={handleOnCloseCreate}
				/>
			)}
		</VStack>
	);
};

export default BlockingInstanceList;
