import { Link } from 'react-router-dom';
import {
	Button,
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	theme,
	Tr,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { useValidateCryptoSubscriptionMutation } from 'services/ops/crypto';
import { CryptoSubscription } from 'types/crypto.types';

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

const CryptoSubTable = ({
	subscriptionToInspect,
	setUserToTrack,
}: {
	subscriptionToInspect: CryptoSubscription[];
	setUserToTrack: (userEmail: string) => void;
}) => {
	const toast = useThemedToast();
	// const [showConfirmModal, setShowConfirmModal] = useState(false);

	// Important to do loading control
	const [triggerAccountValidation, { isLoading }] = useValidateCryptoSubscriptionMutation();

	return (
		<VStack w="100%" align="start" spacing="24px">
			{/*<ConfirmationModalReimbursement*/}
			{/*	show={showConfirmModal}*/}
			{/*	nbSponsorships={selectedAffiliate.size}*/}
			{/*	onConfirm={onMarkAsPaid}*/}
			{/*	onClose={() => setShowConfirmModal(false)}*/}
			{/*/>*/}

			<TableContainer w="100%" bg="white" paddingTop="8px" borderRadius="8px" border="1px solid" borderColor="gray.300">
				<Table w="100%" variant="simple" size="md" __css={{ tableLayout: 'fixed' }}>
					<Thead w="100%">
						<Tr w="100%">
							<TableHeader w="300px">User</TableHeader>
							<TableHeader w="300px">Risk Level</TableHeader>
							<TableHeader w="300px">Risk Details</TableHeader>
						</Tr>
					</Thead>
					<Tbody w="100%">
						{subscriptionToInspect.map((sub) => (
							<Link to={`/ops/super/subscription/${sub?.id}?productType=CRYPTO`} key={sub?.id}>
								<Tr w="100%" key={sub?.id} bg="gray.white" onClick={() => setUserToTrack(sub?.user?.email)}>
									<TableValue _hover={{ bg: theme.colors.gray[100] }} cursor="pointer">
										<Link to={`/ops/super/client/${sub?.user?.id}`}>
											<VStack align="start">
												<Text>{sub?.user?.email}</Text>
											</VStack>
										</Link>
									</TableValue>

									<TableValue>
										<Text fontWeight="600">{sub.riskRating}</Text>
									</TableValue>

									<TableValue>
										<Text fontWeight="600">{sub.riskExplanation.toString().replace(',', ', ')}</Text>
									</TableValue>

									<TableValue>
										<Link to={sub?.partnerAdequationUrl || '/'} target="_blank">
											<VStack align="start">
												<Text>Voir le flow</Text>
											</VStack>
										</Link>
									</TableValue>

									<TableValue>
										<Wrap>
											<Button
												onClick={async () => {
													try {
														await triggerAccountValidation({ email: sub.user.email });
														toast({
															title: 'Succès',
															description: 'Le compte a bien été validé',
															status: 'success',
														});
													} catch (error) {
														toast({
															title: 'Erreur',
															description: error?.toString(),
															status: 'error',
														});
													}
												}}
												isDisabled={isLoading}
											>
												valider
											</Button>
										</Wrap>
									</TableValue>
								</Tr>
							</Link>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</VStack>
	);
};
export default CryptoSubTable;
