import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, chakra, VStack } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import {
	BackTestFrequency,
	CrossAssetBacktestBody,
	useGenerateNewBacktestMutation,
} from 'services/cpm/cross-asset-backtest';
import { isNotNone } from 'utils/functions';

import BackTestDetails from './BacktestDetails';
import InvestmentPreferences from './InvestmentPreferences';
import InvestmentProject from './InvestmentProject';
import Output from './Output';
import Portfolio from './Portfolio';

const sections = [
	{
		title: "Projet d'investissement",
		Component: InvestmentProject,
	},
	{
		title: "Préférences d'investissement",
		Component: InvestmentPreferences,
	},
	{
		title: 'Portefeuille cross assets',
		Component: Portfolio,
	},
	{
		title: 'Détails du backtest',
		Component: BackTestDetails,
	},
];

const CpmCrossAssetBacktest: FC = () => {
	const formMethods = useForm<CrossAssetBacktestBody>({
		defaultValues: {
			investmentHorizon: 10,
			initialDepositAmount: 10_000,
			amountVP: 0,
			fees: 0,
			backtestFrequency: BackTestFrequency.MONTHLY,
		},
	});

	const [triggerNewBacktestGeneration, { isLoading, data: labResult }] = useGenerateNewBacktestMutation();

	const onSubmit = (data: CrossAssetBacktestBody) => {
		triggerNewBacktestGeneration({
			amountVP: +data.amountVP,
			backtestFrequency: data.backtestFrequency,
			fees: +data.fees,
			initialDepositAmount: +data.initialDepositAmount,
			investmentHorizon: +data.investmentHorizon,
			vehicleRisk: data.vehicleRisk ? +data.vehicleRisk : undefined,
			directInvestments: data.directInvestments,
			portfolioType: data.portfolioType,
			vehicleType: data.vehicleType,
			esg: data.esg,
			isLombardLoan: data.isLombardLoan,
			risk: data.risk ? +data.risk : undefined,
		}).unwrap();
	};

	const watchVehicleType = formMethods.watch('vehicleType');

	return (
		<chakra.form w="100%" onSubmit={formMethods.handleSubmit(onSubmit)}>
			<VStack w="100%" p="32px" spacing="32px">
				<FormProvider {...formMethods}>
					{sections.map((s) => (
						<Cardlayout title={s.title} key={s.title}>
							<s.Component />
						</Cardlayout>
					))}
				</FormProvider>
				<Button isLoading={isLoading} type="submit">
					Générer le backtest
				</Button>
				{isNotNone(labResult) && <Output data={labResult} vehicleType={watchVehicleType!} />}
			</VStack>
		</chakra.form>
	);
};

export default CpmCrossAssetBacktest;
