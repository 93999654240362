import { FC } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'd3-format';

type LineChartProps = {
	title?: string;
	xTitle?: string;
	yTitle?: string;
	hideLegend?: boolean;
	data: { id: string; data: { x: string; y: number }[] }[];
};

const LineChart: FC<LineChartProps> = ({ title, hideLegend, xTitle, yTitle, data }) => (
	<VStack w="100%">
		{title && <Text fontSize="xl">{title}</Text>}
		<Box h="600px" w="100%">
			<ResponsiveLine
				data={data}
				margin={{ top: 20, right: hideLegend ? 30 : 200, bottom: 50, left: 90 }}
				xScale={{
					type: 'time',
					format: '%Y-%m-%d',
				}}
				xFormat="time:%Y"
				yScale={{
					min: 'auto',
					type: 'linear',
				}}
				legends={
					hideLegend
						? undefined
						: [
								{
									anchor: 'right',
									direction: 'column',
									itemWidth: 100,
									itemHeight: 20,
									itemsSpacing: 5,
									symbolSize: 20,
									itemDirection: 'left-to-right',
									translateX: 120,
								},
						  ]
				}
				axisBottom={{
					legend: xTitle,
					legendOffset: 36,
					legendPosition: 'middle',
					format: '%Y',
				}}
				axisLeft={{
					legend: yTitle,
					legendOffset: -60,
					legendPosition: 'middle',
					format: (value) => format('.2s')(value),
				}}
				colors={{ scheme: 'category10' }}
				useMesh
				tooltip={({ point }) => (
					<VStack bg="white" p="12px" borderRadius="4px" boxShadow="md">
						<Text color={point.serieColor} fontWeight="bold">
							{point.serieId}
						</Text>
						<HStack fontSize="12px">
							<Text fontWeight="bold">{point.data.xFormatted}</Text>
							<Text>{format(',.3f')(Number(point.data.yFormatted))} €</Text>
						</HStack>
					</VStack>
				)}
			/>
		</Box>
	</VStack>
);

export default LineChart;
