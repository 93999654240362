import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, HStack, Skeleton, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import { useGetClientByEmailQuery, useUpdateFeatureFlagsMutation } from 'services/client';

type UpdateFeatureFlagsProps = {
	email: string;
};

const UpdateFeatureFlags: FC<UpdateFeatureFlagsProps> = ({ email }) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const { data: client, isFetching } = useGetClientByEmailQuery(email);
	const [updateFeatureFlags, { isLoading: isUpdateLoading }] = useUpdateFeatureFlagsMutation();

	const oldFlags = useMemo(
		() =>
			Object.entries(client?.featureFlags ?? {})
				.filter(([, isActive]) => isActive)
				.map(([key]) => key),
		[client],
	);

	useEffect(() => {
		setFeatureFlags(oldFlags);
	}, [oldFlags]);

	const [featureFlags, setFeatureFlags] = useState<string[]>(oldFlags);

	const handleFeatureFlagsUpdate = async () => {
		updateFeatureFlags({ email, featureFlags })
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: 'Feature flags mis à jour avec succès' });
				closeValidation();
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	return (
		<VStack align="start" spacing="32px">
			<Skeleton isLoaded={!isFetching} w="100%">
				<HStack w="100%">
					<Box w="100%">
						<CreatableSelect
							formatCreateLabel={(inputValue) => `Ajouter le flag "${inputValue}"`}
							placeholder="Sélectionner les flags"
							noOptionsMessage={() => 'Aucune option trouvée'}
							isMulti
							onChange={(e) => setFeatureFlags(e.map((ff) => ff.value))}
							value={featureFlags.map((ff) => ({ label: ff, value: ff }))}
							options={oldFlags.map((ff) => ({ label: ff, value: ff }))}
						/>
					</Box>
					<Button colorScheme="blue" alignSelf="end" onClick={openValidation}>
						Valider
					</Button>
				</HStack>
			</Skeleton>
			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modifier les feature flags d'un client"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour les feature flags du client <b>{email}</b>
						<br />
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={closeValidation}>Annuler</Button>
						<Button colorScheme="red" onClick={handleFeatureFlagsUpdate} ml={3} isLoading={isUpdateLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdateFeatureFlags;
