import { FC, useMemo, useState } from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import { AllDeal, useUpdateDealMutation } from 'services/deal';

import BankAccountList from '.';

type LinkBankAccountModalProps = {
	deal: AllDeal;
	isOpen: boolean;
	onClose: () => void;
};

const LinkBankAccountModal: FC<LinkBankAccountModalProps> = ({ deal, isOpen, onClose }) => {
	const [select, setSelect] = useState<string | null>(
		'bankInformationId' in deal ? (deal.bankInformationId as string) : null,
	);

	const [updateDeal] = useUpdateDealMutation();

	const isBankAccountIdDirty = useMemo(() => {
		if (!select) return false;
		return 'bankInformationId' in deal ? deal.bankInformationId !== select : true;
	}, [deal, select]);

	return (
		<CustomModal
			size="6xl"
			headersProps={{ children: <Text fontSize="18px">Associer un compte bancaire</Text> }}
			isOpen={isOpen}
			onClose={onClose}
		>
			<VStack align="start" spacing="12px">
				<BankAccountList selectedBankId={select} onSelect={(v: string) => setSelect(v)} email={deal.user.email} />
				<Button
					colorScheme="blue"
					isDisabled={!isBankAccountIdDirty || !select}
					onClick={() => {
						if (isBankAccountIdDirty && select)
							updateDeal({ id: deal.id, productType: deal.productType, bankAccountId: select });
						onClose();
					}}
				>
					Modifier
				</Button>
			</VStack>
		</CustomModal>
	);
};

export default LinkBankAccountModal;
