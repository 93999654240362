import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, VStack } from '@chakra-ui/react';
import { format } from 'd3-format';

import NumberInputWithStepper from 'components/NumberInput';
import { ScpiCreditSimulationForm } from 'pages/cpm/product-propale/scpiCredit/forms/formTypes';

type CreditInformationsProps = {
	formMethods: UseFormReturn<ScpiCreditSimulationForm>;
};

const CreditInformations: FC<CreditInformationsProps> = ({ formMethods: { control, watch } }) => {
	const creditDuration = watch('creditDuration');

	return (
		<VStack align="start" spacing="30px" w="100%">
			<VStack w="100%" align="start">
				<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
					<Text variant="Text-S-Regular">Durée du crédit</Text>
					<Text fontSize="sm" color="grey.500">
						{creditDuration}/25 ans
					</Text>
				</HStack>
				<Controller
					name="creditDuration"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Slider value={value} min={4} max={25} step={1} onChange={onChange}>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb />
						</Slider>
					)}
				/>
			</VStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Montant du Crédit</Text>
				<Controller
					name="creditAmount"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={100}
							value={format(',.3~f')(field.value) + ' €'}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</VStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Épargne mensuelle max</Text>
				<Controller
					name="maxMonthlySavings"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={100}
							value={`${format(',.3~f')(field.value)} €`}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</VStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Revenus annuels nets imposables du foyer fiscal</Text>
				<Controller
					name="annualNetIncome"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={100}
							value={`${format(',.3~f')(field.value)} €`}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</VStack>
			<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
				<Text variant="Text-S-Regular">Taux d'intérêt</Text>
				<Controller
					name="interestRate"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={1}
							value={`${field.value} %`}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</HStack>
		</VStack>
	);
};

export default CreditInformations;
