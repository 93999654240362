import { Box, Text, VStack } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'd3-format';

const LineChart = ({
	data,
	xTitle,
	yTitle,
}: {
	xTitle: string;
	yTitle: string;
	data: { id: string; value: number }[];
}): JSX.Element => (
	<Box h="350px" w="600px" bg="white">
		<ResponsiveLine
			data={Object.keys(data).map((key) => ({
				id: key,
				data: data.map((d) => ({ x: d.id, y: d.value })),
			}))}
			margin={{ top: 20, right: 100, bottom: 50, left: 90 }}
			xScale={{
				type: 'linear',
				min: data.map((d) => Number(d.id)).reduce((a, b) => Math.min(a, b)),
				max: data.map((d) => Number(d.id)).reduce((a, b) => Math.max(a, b)),
			}}
			yScale={{
				type: 'linear',
				min: data.map((d) => Number(d.value)).reduce((a, b) => Math.min(a, b)),
				max: data.map((d) => Number(d.value)).reduce((a, b) => Math.max(a, b)),
			}}
			axisBottom={{
				legend: xTitle,
				legendOffset: 36,
				legendPosition: 'middle',
			}}
			axisLeft={{
				legend: yTitle,
				legendOffset: -60,
				legendPosition: 'middle',
				format: (value) => format('.2s')(value),
			}}
			colors={{ scheme: 'category10' }}
			useMesh
			tooltip={({ point }) => (
				<VStack bg="white" p={4} borderRadius={4} boxShadow="md">
					<Text>Année {point.data.xFormatted}</Text>
					<Text>
						{xTitle}: {format(',.3f')(Number(point.data.yFormatted))}
					</Text>
				</VStack>
			)}
		/>
	</Box>
);

export default LineChart;
