import { Fund } from 'types/airtable/immo-pe.airtable.type';
import { PEFund } from 'types/airtable/pe.airtable.type';
import { SCPIFund } from 'types/airtable/scpi.airtable.type';
import { StructuredProductFund } from 'types/airtable/structured.airtable.type';
import { ArtFund } from 'types/art.type';
import { AvLuxProvider, avLuxProviderLabels } from 'types/avLux.type';
import { CrowdfundingFund } from 'types/crowdfunding.type';
import { GirardinProvider, girardinProviderLabels } from 'types/girardin.type';
import { InsuranceProvider } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { SavingsAccountProvider, savingsAccountProviderLabels } from 'types/savingsAccount.type';

export const investPortfolioOptions: Array<{ id: PortfolioType; name: string }> = [
	{ id: PortfolioType.FLAGSHIP, name: 'Flagship' },
	{ id: PortfolioType.ESSENTIAL, name: 'Essential' },
	{ id: PortfolioType.ELITE, name: 'Elite' },
	{ id: PortfolioType.TITANIUM, name: 'Titanium' },
	{ id: PortfolioType.CUSTOM, name: 'Personnalisé' },
];

export const investSelectOptions: Array<{ id: InsuranceProvider; name: string }> = [
	{ id: InsuranceProvider.APICIL, name: 'APICIL' },
	{ id: InsuranceProvider.GENERALI, name: 'GENERALI' },
];

export const avLuxSelectOptions: Array<{ id: AvLuxProvider; name: string }> = [
	{ id: AvLuxProvider.ONELIFE, name: avLuxProviderLabels[AvLuxProvider.ONELIFE] },
];

export const cashSelectOptions: Array<{ id: SavingsAccountProvider; name: string }> = [
	{ id: SavingsAccountProvider.CFCAL, name: savingsAccountProviderLabels[SavingsAccountProvider.CFCAL] },
];

export const girardinSelectOptions: Array<{ id: GirardinProvider; name: string }> = [
	{ id: GirardinProvider.INTER_INVEST, name: girardinProviderLabels[GirardinProvider.INTER_INVEST] },
	{ id: GirardinProvider.CLUB_FUNDING, name: girardinProviderLabels[GirardinProvider.CLUB_FUNDING] },
];

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const sortString = (a: string, b: string) => {
	const x = a.toLowerCase();
	const y = b.toLowerCase();
	return x < y ? -1 : x > y ? 1 : 0;
};

const formatDealName = (deal: CrowdfundingFund | ArtFund | StructuredProductFund) => {
	const statusDisplay = deal.status === 'MAIN_DEAL' ? ' - EN COURS' : '';
	return `${deal.name}${statusDisplay}`;
};

export const getFundList = (
	product: string | undefined,
	scpiFunds: SCPIFund[] | undefined,
	peFunds: PEFund[] | undefined,
	artFunds: ArtFund[] | undefined,
	crwdfdgFunds: CrowdfundingFund[] | undefined,
	spFunds: StructuredProductFund[] | undefined,
): Fund[] => {
	let fundList: unknown[] = [];
	if (product === 'SCPI' && scpiFunds) fundList = scpiFunds;
	if (product === 'PE' && peFunds) fundList = peFunds;
	if (product === 'STRUCTURED' && spFunds) fundList = spFunds.map((f) => ({ name: formatDealName(f), id: f.id }));
	if (product === 'INVEST' && investSelectOptions) fundList = investSelectOptions;
	if (product === 'CER' && cashSelectOptions) fundList = cashSelectOptions;
	if (product === 'CAT' && cashSelectOptions) fundList = cashSelectOptions;
	if (product === 'ART' && artFunds) fundList = artFunds.map((f) => ({ name: formatDealName(f), id: f.id }));
	if (product === 'CROWDFUNDING' && crwdfdgFunds)
		fundList = crwdfdgFunds.map((f) => ({ name: formatDealName(f), id: f.id }));
	if (product === 'GIRARDIN' && girardinSelectOptions) fundList = girardinSelectOptions;
	if (product === 'AV_LUX' && avLuxSelectOptions) fundList = avLuxSelectOptions;
	return fundList as Fund[];
};
