import { FC } from 'react';
import { Box, FormLabel, Textarea } from '@chakra-ui/react';

import {
	ArtData,
	AvLuxData,
	CashData,
	CrowdfundingFundData,
	GirardinData,
	InvestData,
	PeFundData,
	ScpiFundData,
	StructuredData,
} from 'pages/cpm/product-propale/propaleData.type';

type PropaleData =
	| ArtData
	| ScpiFundData
	| PeFundData
	| StructuredData
	| InvestData
	| CrowdfundingFundData
	| GirardinData
	| AvLuxData
	| CashData;

type AddCommentPropaleProps = {
	data: PropaleData;
	editData: React.Dispatch<React.SetStateAction<PropaleData[]>>;
};

const AddCommentPropale: FC<AddCommentPropaleProps> = ({ data, editData }) => (
	<Box w="100%">
		<FormLabel fontSize="sm">Commentaire</FormLabel>
		<Textarea
			placeholder="Ajoutez un commentaire (optionnel)"
			value={data.comment}
			onChange={(e) =>
				editData((old) => {
					const newFunds = [...old];
					newFunds.splice(newFunds.indexOf(data), 1);
					return [...newFunds, { ...data, comment: e.target.value }].sort((a, b) => a.id - b.id);
				})
			}
		/>
	</Box>
);

export default AddCommentPropale;
