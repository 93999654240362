import { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import NumberInputWithStepper from 'components/NumberInput';
import {
	CrossAssetBacktestBody,
	DirectInvestmentProduct,
	directInvestmentProducts,
} from 'services/cpm/cross-asset-backtest';

const DirectInvestment: FC = () => {
	const { control, watch, setValue, resetField } = useFormContext<CrossAssetBacktestBody>();
	const [productsActive, setProductsActive] = useState<Record<string, boolean>>({});

	const handleActivateProduct = useCallback(
		(v: boolean, key: DirectInvestmentProduct) => {
			setProductsActive((prev) => ({ ...prev, [key]: v }));
			if (v) {
				setValue(`directInvestments.${key}.usingRamifyRec`, true);
				if (key === 'fs') {
					setValue(`directInvestments.${key}.maturity`, 0);
					setValue(`directInvestments.${key}.annualYield`, 0);
				}
			} else resetField(`directInvestments.${key}`, { defaultValue: undefined });
		},
		[resetField, setValue],
	);

	const handleCustomWeights = useCallback(
		(v: boolean, key: DirectInvestmentProduct) => {
			if (v) {
				setValue(`directInvestments.${key}.usingRamifyRec`, v);
				setValue(`directInvestments.${key}.percentage`, undefined);
				setValue(`directInvestments.${key}.fees`, undefined);
			} else {
				setValue(`directInvestments.${key}.usingRamifyRec`, v);
				setValue(`directInvestments.${key}.percentage`, 0);
				setValue(`directInvestments.${key}.fees`, 0);
			}
		},
		[setValue],
	);

	return (
		<Cardlayout title="Investissement en direct">
			<HStack align="start" w="100%" spacing="24px">
				{directInvestmentProducts.map((product) => (
					<Cardlayout title={product.label} key={product.key}>
						<VStack w="100%" spacing="12px">
							<FormControl>
								<FormLabel size="sm">{product.label} en direct ?</FormLabel>
								<RadioGroup
									onChange={(v) => handleActivateProduct(v === 'true', product.key)}
									value={productsActive[product.key] ? 'true' : 'false'}
								>
									<HStack>
										<Radio value="false">Non</Radio>
										<Radio value="true">Oui</Radio>
									</HStack>
								</RadioGroup>
							</FormControl>
							{productsActive[product.key] && (
								<>
									{product.key === 'fs' && (
										<>
											<FormControl>
												<FormLabel size="sm">Maturité</FormLabel>
												<Controller
													name={`directInvestments.${product.key}.maturity`}
													control={control}
													render={({ field }) => (
														<NumberInputWithStepper {...field} value={`${field.value}`} step={1} />
													)}
												/>
											</FormControl>
											<FormControl>
												<FormLabel size="sm">Rendement annuel</FormLabel>
												<Controller
													name={`directInvestments.${product.key}.annualYield`}
													control={control}
													render={({ field }) => (
														<NumberInputWithStepper {...field} value={`${field.value}`} step={0.5} min={0} max={100} />
													)}
												/>
											</FormControl>
										</>
									)}
									<FormControl>
										<FormLabel size="sm">Utiliser la recommandation Ramify</FormLabel>
										<Controller
											name={`directInvestments.${product.key}.usingRamifyRec`}
											control={control}
											render={({ field }) => (
												<RadioGroup
													{...field}
													onChange={(v) => handleCustomWeights(v === 'true', product.key)}
													value={field.value ? 'true' : 'false'}
												>
													<HStack>
														<Radio value="false">Non</Radio>
														<Radio value="true">Oui</Radio>
													</HStack>
												</RadioGroup>
											)}
										/>
									</FormControl>
									{!watch(`directInvestments.${product.key}.usingRamifyRec`) && (
										<>
											<FormControl>
												<FormLabel size="sm">Pourcentage</FormLabel>
												<Controller
													name={`directInvestments.${product.key}.percentage`}
													control={control}
													render={({ field }) => (
														<NumberInputWithStepper {...field} value={field.value} step={0.5} min={0} max={100} />
													)}
												/>
											</FormControl>
											<FormControl>
												<FormLabel size="sm">Frais de gestion</FormLabel>
												<Controller
													name={`directInvestments.${product.key}.fees`}
													control={control}
													render={({ field }) => (
														<NumberInputWithStepper {...field} value={field.value} step={0.5} min={0} max={100} />
													)}
												/>
											</FormControl>
										</>
									)}
								</>
							)}
						</VStack>
					</Cardlayout>
				))}
			</HStack>
		</Cardlayout>
	);
};

export default DirectInvestment;
