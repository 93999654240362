import { FormLabel, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { VersementData } from 'pages/cpm/product-propale/propaleData.type';
import { VersementRequestType } from 'types/invest-deal.type';

type VersementCardProps = {
	data: VersementData;
	editData: React.Dispatch<React.SetStateAction<VersementData[]>>;
};

export const VersementCard = ({ data, editData }: VersementCardProps) => {
	const minAmount = data.versementRequestType === VersementRequestType.PROGRAMME ? 100 : 300;

	return (
		<HStack w="100%">
			<VStack align="start" spacing="16px" w="100%">
				<FormLabel fontSize="sm" w="150px">
					Montant
				</FormLabel>
				<NumberInputWithStepper
					step={1}
					min={minAmount}
					value={data.initialDepositAmount + '€'}
					onChange={(initialDepositAmount) =>
						editData((old) => {
							const newData = [...old];
							newData.splice(newData.indexOf(data), 1);
							return [...newData, { ...data, initialDepositAmount: +initialDepositAmount }].sort((a, b) => a.id - b.id);
						})
					}
				/>
			</VStack>
			<VStack align="start" spacing="16px" w="100%">
				<FormLabel fontSize="sm">Versement</FormLabel>
				<RadioGroup
					value={data.versementRequestType}
					onChange={(type) =>
						editData((old) => {
							const newData = [...old];
							newData.splice(newData.indexOf(data), 1);
							return [...newData, { ...data, versementRequestType: type as VersementRequestType }];
						})
					}
				>
					<HStack>
						<Radio size="sm" value={VersementRequestType.PROGRAMME}>
							Versement programmé
						</Radio>
						<Radio size="sm" value={VersementRequestType.COMPLEMENTAIRE}>
							Versement complémentaire
						</Radio>
					</HStack>
				</RadioGroup>
			</VStack>
		</HStack>
	);
};
