import { PESubscription } from 'types/airtable/pe.airtable.type';
import { SCPISubscription } from 'types/airtable/scpi.airtable.type';
import { ArtSubscription } from 'types/art.type';
import { CrowdfundingSubscription } from 'types/crowdfunding.type';
import { ProductType } from 'types/global.type';
import { InvestmentPreferences } from 'types/investmentPreferences.type';
import { MailingFrameworkConversation } from 'types/mailing-framework.type';
import { MoralPerson } from 'types/moralPerson.type';
import { SavingsAccount } from 'types/savingsAccount.type';
import { DocumentName } from 'utils/documentNamingMap';

import { Envelope, InvestDeal } from './invest-deal.type';
import { OpsProperties } from './ops-properties.type';
import { User } from './user.type';

export enum BlockingInstanceStatus {
	OPEN = 'OPEN',
	SENT_TO_CLIENT = 'SENT_TO_CLIENT',
	DOCUMENTS_SUBMITTED = 'DOCUMENTS_SUBMITTED',
	SENT_TO_PARTNER = 'SENT_TO_PARTNER',
	CLOSED = 'CLOSED',
}

export enum BlockingInstanceLifecycle {
	UPDATING_INFO = 'UPDATING_INFO',
	MISSING_INFO = 'MISSING_INFO',
	ROADBLOCK = 'ROADBLOCK',
	BLOCKED_OPERATION = 'BLOCKED_OPERATION',
}

export type BlockingInstanceDocument = {
	id: string;
	createdAt: string;
	updatedAt: string;
	documentName: DocumentName;
	comment: string;
	submitted: boolean;
};

export type BlockingInstanceLinkedEntity =
	| (InvestDeal & { envelope?: Envelope; investmentPreferences?: InvestmentPreferences })
	| (SavingsAccount & { moralPerson?: MoralPerson })
	| ArtSubscription
	| CrowdfundingSubscription
	| PESubscription
	| SCPISubscription;

export type BlockingInstance = {
	id: string;
	createdAt: string;
	updatedAt: string;

	lifecycle: BlockingInstanceLifecycle;
	product: ProductType;
	missingDocuments: BlockingInstanceDocument[];
	partner: string;
	status: BlockingInstanceStatus;

	message?: string;

	mailId: string;
	mailSubject: string;
	mailBody: string;
	mailSender?: string;

	openedAt?: boolean;
	hasBeenSentToClient: boolean;
	sentToClientAt?: string;
	hasBeenDocumentsSubmitted: boolean;
	documentsSubmittedAt?: boolean;
	hasBeenSentToPartner: boolean;
	sentToPartnerAt?: string;
	closedAt?: boolean;

	user?: User;
	userId?: string;

	linkedEntityId?: string;
	linkedEntity?: BlockingInstanceLinkedEntity;

	opsPropertiesId?: string;
	opsProperties?: OpsProperties;

	conversation?: MailingFrameworkConversation;
};
