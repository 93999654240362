import {
	LabSCPICreditSimulationResponse,
	LabSCPIDirectSimulationBody,
	LabSCPIRecommendationResponse,
} from 'services/cpm/scpi-direct';
import { dashboardApi } from 'store/api';

export type ScpiCreditRecommendationBody = {
	investment_horizon: number;
	family_situation: string;
	own_initial_investment: number;
	credit: number;
	credit_horizon: number;
	credit_rate: number;
	credit_actor: string;
	personne_morale: boolean;
	isISR: boolean;
	reco_filter: boolean;
	retool_reco_filter: boolean;
	annual_net_revenue: number;
	number_children: number;
	epargne_mensuel_max?: number | null;
	etranger_percentage_minimum?: number | null;
	scpi_maximal_number_per_partner?: number | null;
	scpi_maximal_percentage_per_partner?: number | null;
	has_filled_tax_form: boolean;
	has_filled_tax_shares_form: boolean;
};

export type ScpiCreditSimulationBody = LabSCPIDirectSimulationBody & {
	own_initial_investment: number;
	credit: number;
	credit_horizon: number;
	credit_rate: number;
	credit_actor: string;
};

type ScpiInvestment = {
	scpi: string;
	amount: number;
};

export type ScpiCreditGenerateNotionPageBody = {
	first_name: string;
	last_name: string;

	personal_contribution: string;
	credit_amount: string;
	credit_horizon: string;
	tri: string;
	diversification: string;

	performance: string;
	distribution_rate: string;
	annual_revaluation: string;
	abroad_percentage: string;
	interest_rate: string;
	entry_fees: string;
	cumul_dividends: string;
	mean_div_year: string;
	mean_div_month: string;

	nb_scpi: string;
	mensualite: string;
	epargne: string;

	scpi_investments: ScpiInvestment[];

	repartition: {
		montant: number;
		label: string;
	}[];

	simulation_result_table: {
		valo_capital_net: Record<number, number>;
		dividendes_brutes: Record<number, number>;
		interet_annuel_paye: Record<number, number>;
		net_cashflow: Record<number, number>;
		principal_annuel_paye: Record<number, number>;
		tax_annuel_paye: Record<number, number>;
		tax_evite: Record<number, number>;
	};
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createScpiCreditRecommendation: builder.mutation<LabSCPIRecommendationResponse, ScpiCreditRecommendationBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-scpi-credit/recommendation',
				method: 'POST',
				body: data,
			}),
		}),
		createScpiCreditSimulation: builder.mutation<LabSCPICreditSimulationResponse, ScpiCreditSimulationBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-scpi-credit/simulation',
				method: 'POST',
				body: data,
			}),
		}),
		generateNotionPage: builder.mutation<void, ScpiCreditGenerateNotionPageBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-scpi-credit/notion-link',
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

export const {
	useCreateScpiCreditRecommendationMutation,
	useCreateScpiCreditSimulationMutation,
	useGenerateNotionPageMutation,
} = endpoints;
