import { FC } from 'react';
import { FormControl, FormLabel, HStack, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { StructuredData } from 'pages/cpm/product-propale/propaleData.type';

import AddCommentPropale from './common/AddCommentPropale';

type StructuredCardProps = {
	data: StructuredData;
	editData: React.Dispatch<React.SetStateAction<StructuredData[]>>;
};

const StructuredCard: FC<StructuredCardProps> = ({ data, editData }) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack w="100%">
			<FormControl>
				<FormLabel fontSize="sm">Montant</FormLabel>
				<NumberInputWithStepper
					step={1_000}
					min={1_000}
					value={data.initialDepositAmount + '€'}
					onChange={(initialDepositAmount) =>
						editData((old) => {
							const newData = [...old];
							newData.splice(newData.indexOf(data), 1);
							return [...newData, { ...data, initialDepositAmount: +initialDepositAmount }].sort((a, b) => a.id - b.id);
						})
					}
				/>
			</FormControl>
		</HStack>
		{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
		<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
	</VStack>
);

export default StructuredCard;
