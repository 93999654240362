import { Box, FormLabel, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { ArtData } from 'pages/cpm/product-propale/propaleData.type';
import { LegalEntity } from 'types/global.type';

import AddCommentPropale from './common/AddCommentPropale';

type ArtCardProps = {
	data: ArtData;
	editData: React.Dispatch<React.SetStateAction<ArtData[]>>;
};

export const ArtCard = ({ data, editData }: ArtCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack w="100%">
			<Box flex="1">
				<FormLabel fontSize="sm">Vous souhaitez investir en tant que:</FormLabel>
				<RadioGroup
					value={data.legalEntity}
					onChange={(legal) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, legalEntity: legal as LegalEntity }].sort((a, b) => a.id - b.id);
						});
					}}
				>
					<HStack>
						<Radio size="sm" value={LegalEntity.PHYSICAL}>
							Personne physique
						</Radio>
						<Radio size="sm" value={LegalEntity.MORAL}>
							Personne morale
						</Radio>
					</HStack>
				</RadioGroup>
			</Box>

			<Box flex="1">
				<FormLabel fontSize="sm">Montant</FormLabel>
				<NumberInputWithStepper
					step={1000}
					min={20000}
					value={data.initialDepositAmount + '€'}
					onChange={(amount) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, initialDepositAmount: +amount }].sort();
						});
					}}
				/>
			</Box>
		</HStack>
		{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
		<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
	</VStack>
);
