import { FC, useState } from 'react';
import Markdown from 'react-markdown';
import { ChatIcon, LinkIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	chakra,
	CloseButton,
	HStack,
	Image,
	Text,
	Wrap,
} from '@chakra-ui/react';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import AlertDialog from 'components/AlertDialog';
import useDealBoInfo from 'hooks/useDealBoInfo';
import { AllDeal } from 'services/deal';
import { AirtableAttachment } from 'types/airtable/global.airtable.type';
import { isNotNone } from 'utils/functions';

type DealBoInfoProps = {
	deal: AllDeal;
};

const DealBoInfo: FC<DealBoInfoProps> = ({ deal }) => {
	const dealBoInfo = useDealBoInfo(deal);

	const [previewFile, setPreviewFile] = useState<AirtableAttachment | null>(null);

	if (dealBoInfo === null) return null;

	return (
		<Accordion w="100%" pt="20px" allowToggle allowMultiple>
			{dealBoInfo.description && (
				<AccordionItem>
					<AccordionButton fontWeight="bold" justifyContent="space-between">
						<HStack>
							<ChatIcon />
							<chakra.span>Infos</chakra.span>
						</HStack>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						{dealBoInfo?.description && (
							<Box p="24px">
								<Markdown className="markdown" remarkPlugins={[remarkGfm, remarkBreaks]}>
									{dealBoInfo.description}
								</Markdown>
							</Box>
						)}
					</AccordionPanel>
				</AccordionItem>
			)}
			{dealBoInfo.screenshots && (
				<AccordionItem>
					<AccordionButton fontWeight="bold" justifyContent="space-between">
						<HStack>
							<LinkIcon />
							<chakra.span>Médias</chakra.span>
						</HStack>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<AlertDialog
							size="full"
							isOpen={isNotNone(previewFile)}
							onClose={() => setPreviewFile(null)}
							body={
								isNotNone(previewFile) &&
								(previewFile.type.includes('image') ? (
									<chakra.img src={previewFile.url} m="auto" />
								) : (
									<chakra.iframe width="100%" height="88vh" src={`${previewFile.url}`} />
								))
							}
							footer={null}
							header={
								<HStack w="100%" justify="space-between">
									<Text>{previewFile?.filename}</Text>
									<CloseButton onClick={() => setPreviewFile(null)} />
								</HStack>
							}
						/>
						<Wrap shouldWrapChildren w="100%" align="start">
							{dealBoInfo?.screenshots?.map((screenshot) => (
								<Image
									_hover={{
										filter: 'brightness(0.9)',
									}}
									cursor="pointer"
									onClick={() => setPreviewFile(screenshot)}
									maxH="300px"
									key={screenshot.id}
									src={screenshot.thumbnails?.large.url}
								/>
							))}
						</Wrap>
					</AccordionPanel>
				</AccordionItem>
			)}
		</Accordion>
	);
};

export default DealBoInfo;
