import { SubscriptionStatus } from 'types/global.type';
import { User } from 'types/user.type';

enum ClientRiskRating {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGHT = 'HIGHT',
}

enum CryptoTimeHorizon {
	SHORT = 'SHORT',
	MEDIUM = 'MEDIUM',
	LONG = 'LONG',
}

enum CryptoKnowledge {
	NO_KNOWLEDGE = 'NO_KNOWLEDGE',
	KNOWLEDGE = 'KNOWLEDGE',
	EXPERIENCE = 'EXPERIENCE',
}

export type CryptoSubscription = {
	id: string;
	createdAt: Date;
	updatedAt: Date;

	// Ip Verification
	addressIp?: string;
	isIpApproved?: boolean;

	// Client Risk - Update during onboarding and set at account creation
	riskRating: ClientRiskRating;
	riskExplanation: string[];

	// Onboarding data
	cryptoKnowledge?: CryptoKnowledge;
	timeHorizon?: CryptoTimeHorizon;
	pastInvestAmount?: number;

	// have approved the terms and conditions
	isTermsApproved?: boolean;
	CGUVersion?: string;
	acceptDate?: Date;

	// Opening Process
	status: SubscriptionStatus;
	isCryptoPartnerCompleted?: boolean;
	isVirtualIbanPartnerCompleted?: boolean;
	isAdequationPartnerCompleted?: boolean;

	hubspotId?: string;

	partnerAdequationUrl?: string;
	user: User;
};

export enum PartnerAdequationState {
	CREATED = 'CREATED',
	REVIEWING = 'REVIEWING',
	PROCESSING = 'PROCESSING',
	COMPLETED = 'COMPLETED',
	REFUSED = 'REFUSED',
	EXPIRED = 'EXPIRED',
}

export type PartnerAdequation = {
	id: string;
	provider: string;
	externalProviderAccountId: string;

	adequationId: string;
	adequationRunId: string;
	status: PartnerAdequationState;

	partnerAdequationUrl?: string;

	createdAt: Date;
	updatedAt: Date;
};

export type VirtualIbanEndUser = {
	provider: string;
	externalProviderAccountId: string;
};

export type FiatrepublicVirtualIbanAccountResponse = {
	id: string;
	bankDetails: {
		iban: string;
		bic: string;
		accountHolderName: string;
	};
	label: string;
	metadata?: Partial<Record<string, string>>;
};

type PayeeOwner = {
	type: 'END_USER';
	id: string;
};

type FiatrepublicAddress = {
	line1: string;
	city: string;
	postalCode: string;
	country: string; // alpha-2 format
};

type AdditionalDetails = {
	dob: string;
};

type RoutingCode = { value: 'SORT_CODE' | 'BSB'; number: string };

export type FiatRepublicBankDetails = {
	bankName: string;
	bankAddressLine1: string;
	bankAddressCity: string;
	bankAddressPostalCode: string;
	bankAddressState: string;
	iban: string;
	bic: string;
	routingCodes?: RoutingCode[];
	bankCountry: string;
};

export enum FiatrepublicPaymentSchemes {
	SCT = 'SCT',
	EAGLE_NET_TRANSFER = 'EAGLE_NET_TRANSFER',
}

export enum GlobalPaymentTransactionStatus {
	CREATED = 'CREATED',
	PROCESSING = 'PROCESSING',
	COMPLETED = 'COMPLETED',
	REJECTED = 'REJECTED',
	COMPLIANCE_REVIEW = 'COMPLIANCE_REVIEW',
	MEMBER_REVIEW = 'MEMBER_REVIEW',
}

enum GlobalPaymentTransactionType {
	PAYIN = 'PAYIN',
	PAYOUT = 'PAYOUT',
}

export type VirtualIbanPaymentTransactionToReturn = {
	id: string;
	amount: string;
	status: GlobalPaymentTransactionStatus;
	type: GlobalPaymentTransactionType;
	fees?: string;
	iban: string;
	step: string;
	createdAt: Date;
	updatedAt: Date;
};

export type FiatrepublicPayeeResponse = {
	id: string;
	owner: PayeeOwner;
	type: 'PERSON';
	name: string;
	currency: string;
	paymentSchemes: FiatrepublicPaymentSchemes[];
	bankDetails: FiatRepublicBankDetails;
	additionalDetails: AdditionalDetails;
	createdAt: number;
	updatedAt: number;
	metadata?: Partial<Record<string, string>>;
	status: 'CREATED' | 'PROCESSING' | 'AWAITING_APPROVAL' | 'REJECTED' | 'ACTIVE' | 'DELETED';
};

export type CryptoBoUserProfile = {
	subscription: CryptoSubscription;
	partnerAdequation: PartnerAdequation;
	virtualIbanEndUser: VirtualIbanEndUser;
	virtualIbanAccounts?: (FiatrepublicVirtualIbanAccountResponse & {
		userWithdrawAccount: FiatrepublicPayeeResponse;
		userPaymentsTransactions: VirtualIbanPaymentTransactionToReturn[];
	})[];
};

export const OnfidoStatusTranslate: Record<string, string> = {
	[PartnerAdequationState.CREATED]: "Created, en attente de l'utilisateur",
	[PartnerAdequationState.PROCESSING]: 'Processing, Une verification manuelle est en requise !',
	[PartnerAdequationState.REVIEWING]: 'Reviewing',
	[PartnerAdequationState.COMPLETED]: 'Completed',
	[PartnerAdequationState.REFUSED]: 'Refused',
};
