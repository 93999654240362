import { FC, useCallback, useMemo } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, Skeleton, Switch, VStack } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import FilterPopovers, { FILTER_POPOVERS } from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import DealsTable, { dealTableColumns } from 'components/tables/DealsTable';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import useThemedToast from 'hooks/useThemedToast';
import { DealProductOptions, filterDealproductType } from 'pages/ops/super/deal/utils';
import { AllDeal } from 'services/deal';
import { useGetStatusesQuery } from 'services/ops/status';
import { useGetSubscriptionsQuery } from 'services/subscription';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BOContext, SubscriptionStatus } from 'types/global.type';
import { toCSV } from 'utils/functions';

import useSubscriptionListFilters from './utils';

//
// FILTERING & EXPORT
//

type SubscriptionFilters = {
	searchBy: SearchBy;
	input: string;
	productFilter: DealProductOptions[];
	clientTypeFilter: string[];
	statusFilter: SubscriptionStatus[];
	partnerFilter: string[];
	includeDealsWithBI: boolean;
	onlyOverdue: boolean;
	productTypeStatuses: Record<string, StatusLabel> | undefined;
};

const filterDealWithOptions = (deals: AllDeal[], f: SubscriptionFilters) =>
	deals
		// inputs
		.filter((s) => searchByFunc(s, f.searchBy, f.input))
		// product type
		.filter((s) => filterDealproductType(s, f.productFilter))
		// client type
		.filter((s) => {
			if (f.clientTypeFilter.length === 0) return true;
			return (
				(f.clientTypeFilter.includes('isBlack') && s.user.isBlack) ||
				(f.clientTypeFilter.includes('isPhoenix') && s.user.isPhoenix) ||
				(f.clientTypeFilter.includes('isCorporate') && s.user.isCorporate)
			);
		})
		// status
		.filter((s) => 'status' in s && f.statusFilter.includes(s.status as SubscriptionStatus))
		// blocking instance
		.filter((s) => (!f.includeDealsWithBI ? !s.hasBlockingInstance : true))
		// partner
		.filter((s) => (f.partnerFilter.length === 0 ? true : f.partnerFilter.includes(s.partner)))
		// overdue
		.filter((s) => (f.onlyOverdue ? isDealOverdue(s, f.productTypeStatuses) : true));

const buildCsv = (deals: AllDeal[], f: SubscriptionFilters) => {
	const filtered = filterDealWithOptions(deals, f);
	const headerKeys = Object.keys(dealTableColumns);
	const header = headerKeys.map((key) => dealTableColumns[key as keyof typeof dealTableColumns].name);
	const rows = filtered.map((d) =>
		headerKeys.map((key) => dealTableColumns[key as keyof typeof dealTableColumns].get(d)),
	);
	return toCSV([header, ...rows]);
};

//
// COMPONENT
//

type SubscriptionListProps = {
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
	context: Extract<BOContext, 'client' | 'subscription'>;
};

const SubscriptionList: FC<SubscriptionListProps> = ({ customSearchBy, customInput, context }) => {
	const toast = useThemedToast();

	const {
		data: deals,
		isFetching,
		refetch,
	} = useGetSubscriptionsQuery(
		{ searchBy: customSearchBy ?? 'email', input: customInput ?? '' },
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 150000 },
	);
	const partnerOptions = useMemo(() => [...new Set(deals?.map((d) => d.partner))].sort(), [deals]);

	const {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		clientTypeFilter,
		setClientTypeFilter,
		onlyOverdue,
		setOnlyOverdue,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		input,
		setInput,
		searchBy,
		setSearchBy,
		productFilter,
		setProductFilter,
		resetFilters,
	} = useSubscriptionListFilters(context, customSearchBy, customInput, partnerOptions);

	const { data: productTypeStatuses } = useGetStatusesQuery();

	const allFilters = useMemo(
		() => ({
			partnerFilter,
			statusFilter,
			clientTypeFilter,
			onlyOverdue,
			includeDealsWithBI,
			productFilter,
			input,
			searchBy,
			productTypeStatuses,
		}),
		[partnerFilter, statusFilter, clientTypeFilter, onlyOverdue, includeDealsWithBI, productFilter, input, searchBy, productTypeStatuses], // prettier-ignore
	);

	const downloadCsv = useCallback(
		() => fileDownload(buildCsv(deals ?? [], allFilters), 'extract', 'text/csv'),
		[deals, allFilters],
	);

	return (
		<VStack w="100%" align="start">
			{context === 'subscription' && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<SearchByFilter
							size="sm"
							search={input}
							onChangeSearch={setInput}
							searchBy={searchBy}
							onChangeSearchBy={setSearchBy}
							isFetching={isFetching}
							onClearCache={refetch}
						/>

						<FilterPopovers
							size="sm"
							components={[
								{
									title: 'Produit',
									componentProps: {
										value: productFilter,
										onChange: (v: string[]) => setProductFilter(v as DealProductOptions[]),
										options: Object.values(DealProductOptions),
									},
								},
								{
									title: 'Statut',
									componentProps: {
										value: statusFilter,
										onChange: (v: string[]) => setStatusFilter(v as SubscriptionStatus[]),
										options: [SubscriptionStatus.PENDING, SubscriptionStatus.SIGNED],
									},
								},
								{
									title: 'Client type',
									componentProps: {
										onChange: setClientTypeFilter,
										options: ['isBlack', 'isPhoenix', 'isCorporate'],
										value: clientTypeFilter,
									},
								},
								{
									component: FILTER_POPOVERS.PARTNER,
									title: 'Partenaire',
									componentProps: {
										onChange: setPartnerFilter,
										options: partnerOptions,
										value: partnerFilter,
									},
								},
							]}
						/>

						<Button
							size="sm"
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch isChecked={onlyOverdue} onChange={(event) => setOnlyOverdue(event.target.checked)} />}
						>
							Overdue
						</Button>
						<Button
							size="sm"
							_hover={{ cursor: 'auto' }}
							rightIcon={
								<Switch
									isChecked={includeDealsWithBI}
									onChange={(event) => setIncludeDealsWithBI(event.target.checked)}
								/>
							}
						>
							Instances
						</Button>
					</HStack>
					<Menu closeOnSelect={false}>
						<MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem
								onClick={() => {
									toast({ title: 'Filtres réinitialisés', status: 'info' });
									resetFilters();
								}}
							>
								Réinitialiser les filtres
							</MenuItem>
							<MenuItem onClick={() => downloadCsv()}>Exporter la vue</MenuItem>
						</MenuList>
					</Menu>
				</HStack>
			)}

			<Skeleton isLoaded={!isFetching} w="100%">
				<DealsTable
					context={context}
					productTypeStatuses={productTypeStatuses}
					deals={filterDealWithOptions(deals ?? [], allFilters)}
					pageSize={50}
				/>
			</Skeleton>
		</VStack>
	);
};

export default SubscriptionList;
