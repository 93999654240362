import { Box, Button, FormLabel, HStack, Radio, RadioGroup, Switch, Textarea, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { PeFundData } from 'pages/cpm/product-propale/propaleData.type';
import { LegalEntity } from 'types/global.type';

import AddCommentPropale from './common/AddCommentPropale';

type PeFundCardProps = {
	data: PeFundData;
	editData: React.Dispatch<React.SetStateAction<PeFundData[]>>;
};

export const PeFundCard = ({ data, editData }: PeFundCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack w="100%">
			<Box flex="1">
				<FormLabel fontSize="sm">Vous souhaitez investir en tant que:</FormLabel>
				<RadioGroup
					value={data.legalEntity}
					onChange={(legal) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, legalEntity: legal as LegalEntity }].sort((a, b) => a.id - b.id);
						});
					}}
				>
					<HStack>
						<Radio size="sm" value={LegalEntity.PHYSICAL}>
							Personne physique
						</Radio>
						<Radio size="sm" value={LegalEntity.MORAL}>
							Personne morale
						</Radio>
					</HStack>
				</RadioGroup>
			</Box>

			<Box flex="1">
				<FormLabel fontSize="sm">Montant</FormLabel>
				<NumberInputWithStepper
					step={1000}
					min={data.fund.ticket}
					value={data.initialDepositAmount + '€'}
					onChange={(amount) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, initialDepositAmount: +amount }].sort((a, b) => a.id - b.id);
						});
					}}
				/>
			</Box>
		</HStack>
		<VStack w="100%" align="start">
			<Button
				size="sm"
				_hover={{ cursor: 'auto' }}
				rightIcon={
					<Switch
						isChecked={data.isReUp}
						onChange={(e) => {
							editData((old) => {
								const newFunds = [...old];
								newFunds.splice(newFunds.indexOf(data), 1);
								return [...newFunds, { ...data, isReUp: e.target.checked }].sort((a, b) => a.id - b.id);
							});
						}}
					/>
				}
			>
				Re-up
			</Button>

			{data.isReUp && (
				<>
					<Box>
						<FormLabel fontSize="sm">Montant du re-up</FormLabel>
						<NumberInputWithStepper
							step={1000}
							min={0}
							value={data.reUpAmount + '€'}
							onChange={(amount) => {
								editData((old) => {
									const newFunds = [...old];
									newFunds.splice(newFunds.indexOf(data), 1);
									return [...newFunds, { ...data, reUpAmount: +amount }].sort((a, b) => a.id - b.id);
								});
							}}
						/>
					</Box>
					<Box w="100%">
						<FormLabel fontSize="sm">Commentaire</FormLabel>
						<Textarea
							value={data.reUpComment}
							onChange={(e) => {
								editData((old) => {
									const newFunds = [...old];
									newFunds.splice(newFunds.indexOf(data), 1);
									return [...newFunds, { ...data, reUpComment: e.target.value }].sort((a, b) => a.id - b.id);
								});
							}}
						/>
					</Box>
				</>
			)}
		</VStack>
		{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
		<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
	</VStack>
);
