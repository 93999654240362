import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, chakra, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
import { electronicFormatIBAN, validateBIC, validateIBAN } from 'ibantools';

import FilePicker from 'components/FilePicker';
import CustomModal from 'components/Modal';
import useThemedToast from 'hooks/useThemedToast';
import { useCreateBankAccountForUserMutation } from 'services/ops/bank-account';

const checkIbanValidation = (iban: string): boolean => validateIBAN(electronicFormatIBAN(iban) || undefined).valid;

export type AddBankAccountModalProps = {
	isOpen: boolean;
	onClose: () => void;
	clientEmail?: string;
};

type AddBankAccountModalForm = {
	BIC: string;
	IBAN: string;
	holder: string;
	file: File[];
	label?: string;
};

const AddBankAccountModal: FC<AddBankAccountModalProps> = ({ isOpen, onClose, clientEmail }) => {
	const toast = useThemedToast();

	// CREATE NEW ACCOUNT
	const [createBankAccount, { isLoading: isLoadingCreateBankAccount }] = useCreateBankAccountForUserMutation();

	// FORM
	const {
		control,
		register,
		formState: { errors },
		handleSubmit,
		reset,
		watch,
	} = useForm<AddBankAccountModalForm>();

	const file = watch('file');

	const handleResearch = handleSubmit((data) => {
		if (!clientEmail) return;
		const formData = new FormData();

		formData.append('file', data.file[0]);
		formData.append('BIC', data.BIC);
		formData.append('IBAN', data.IBAN);
		formData.append('holder', data.holder);
		if (!!data.label) formData.append('label', data.label);

		createBankAccount({ body: formData, userEmail: clientEmail })
			.unwrap()
			.then(() => {
				onClose();
				reset();
			})
			.catch((e) => toast({ status: 'error', title: 'Erreur', description: e.data.message }));
	});

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			scrollBehavior="inside"
			headersProps={{ children: 'Ajouter un compte bancaire' }}
		>
			<chakra.form w="100%" onSubmit={handleResearch}>
				<VStack align="start" spacing="24px" w="100%">
					<VStack align="start" spacing="4px" w="100%">
						<FormControl isInvalid={!!errors.IBAN}>
							<FormLabel>IBAN</FormLabel>
							<Input
								{...register('IBAN', { required: true, validate: (str) => checkIbanValidation(str) })}
								placeholder="ex : FRXX XXXX XXXX XXXX"
							/>
							{errors.IBAN?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.IBAN?.type === 'validate' && <FormErrorMessage>Votre Iban n'est pas reconnu</FormErrorMessage>}
						</FormControl>
					</VStack>

					<VStack align="start" spacing="4px" w="100%">
						<FormControl isInvalid={!!errors.BIC}>
							<FormLabel>BIC</FormLabel>
							<Input
								{...register('BIC', {
									required: true,
									validate: (str) => validateBIC(str.replace(/\s/g, '')).valid,
								})}
								placeholder="ex: CEPAFR"
							/>
							{errors.BIC?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.BIC?.type === 'validate' && <FormErrorMessage>Votre BIC n'est pas reconnu</FormErrorMessage>}
						</FormControl>
					</VStack>

					<VStack align="start" spacing="4px" w="100%">
						<FormControl isInvalid={!!errors.holder}>
							<FormLabel>Holder</FormLabel>
							<Input
								{...register('holder', { required: { value: true, message: 'Ce champ est nécessaire' } })}
								placeholder="Alexendru..."
							/>
							{errors.holder && <FormErrorMessage>{errors.holder.message}</FormErrorMessage>}
						</FormControl>
					</VStack>

					<VStack align="start" spacing="4px" w="100%">
						<FormControl isInvalid={!!errors.label}>
							<FormLabel>Label (optionnel)</FormLabel>
							<Input {...register('label')} placeholder="Shine" />
							{errors.label && <FormErrorMessage>{errors.label.message}</FormErrorMessage>}
						</FormControl>
					</VStack>

					<VStack align="start" spacing="4px" w="100%">
						<FormControl isInvalid={!!errors.file}>
							<FormLabel>Fichier</FormLabel>
							<Controller
								control={control}
								name="file"
								rules={{ required: { value: true, message: 'Ce champ est nécessaire' } }}
								render={({ field }) => (
									<FilePicker
										multiple={false}
										onDrop={(acceptedFiles) => field.onChange(acceptedFiles)}
										shouldShowSelectButton
										shouldShowSelectedFiles={!!file}
										files={file}
									/>
								)}
							/>

							{errors.file && <FormErrorMessage>{errors.file.message}</FormErrorMessage>}
						</FormControl>
					</VStack>

					<Button colorScheme="blue" isLoading={isLoadingCreateBankAccount} type="submit" alignSelf="end">
						Créer
					</Button>
				</VStack>
			</chakra.form>
		</CustomModal>
	);
};

export default AddBankAccountModal;
