import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { CrossAssetBacktestBody } from 'services/cpm/cross-asset-backtest';

const InvestmentProject: FC = () => {
	const { control } = useFormContext<CrossAssetBacktestBody>();

	return (
		<SimpleGrid columns={2} w="100%" gap="24px">
			<FormControl>
				<FormLabel size="sm">Horizon d'investissement</FormLabel>
				<Controller
					name="investmentHorizon"
					control={control}
					render={({ field }) => <NumberInputWithStepper {...field} step={1} min={0} />}
				/>
			</FormControl>
			<FormControl>
				<FormLabel size="sm">Montant à investir</FormLabel>
				<Controller
					name="initialDepositAmount"
					control={control}
					render={({ field }) => <NumberInputWithStepper {...field} value={field.value} step={1000} min={10000} />}
				/>
			</FormControl>
			<FormControl>
				<FormLabel size="sm">Montant des VP</FormLabel>
				<Controller
					name="amountVP"
					control={control}
					render={({ field }) => <NumberInputWithStepper {...field} value={field.value} step={10} min={0} />}
				/>
			</FormControl>
			<FormControl>
				<FormLabel size="sm">Frais de gestion</FormLabel>
				<Controller
					name="fees"
					control={control}
					render={({ field }) => <NumberInputWithStepper {...field} value={field.value} step={0.5} min={0} max={100} />}
				/>
			</FormControl>
		</SimpleGrid>
	);
};

export default InvestmentProject;
