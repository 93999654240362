import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { format } from 'd3-format';

const ValorisationTable = ({ data }: { data: Record<string, Record<number, number>> }): JSX.Element => {
	const columns = Object.keys(data);
	const maxRows = Math.max(...Object.values(data).map((obj) => Object.keys(obj).length));

	return (
		<Box>
			<TableContainer bg="white" w="100%">
				<Table variant="simple" size="sm">
					<Thead style={{ backgroundColor: '#E7E7E7' }}>
						<Tr>
							<Th>Année</Th>
							{columns.map((col) => (
								<Th key={col}>{col}</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{Array.from({ length: maxRows }, (_, rowIndex) => (
							<Tr key={rowIndex}>
								<Td>{rowIndex}</Td>
								{columns.map((col) => (
									<Td key={col} isNumeric>
										{data[col][rowIndex] !== undefined ? format('.3~f')(data[col][rowIndex]) : '-'}
									</Td>
								))}
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default ValorisationTable;
