import { FC, useCallback, useState } from 'react';
import { Box, HStack, Input, Select, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { format } from 'd3-format';

import { LabSCPIRecommendationResponse } from 'services/cpm/scpi-direct';
import { useGetScpiFundsQuery, useLazyGetScpiDemembrementQuery } from 'services/fund-data';
import { SCPIFund } from 'types/airtable/scpi.airtable.type';

interface ScpiTableProps {
	recommendationTable: LabSCPIRecommendationResponse;
	setRecommendationTable: (value: LabSCPIRecommendationResponse | null) => void;
}

interface ScpiAllocation {
	parts_plein: number;
	parts_nue: number;
	montant_plein: number;
	montant_nu: number;
	id_airtable: string;
	decote_period: number;
}

function isNewRowValid(row: ScpiAllocation, scpiFunds: SCPIFund[] | undefined, newRowName: string) {
	const currentScpiData = scpiFunds?.find((scpi) => scpi.id === newRowName);
	if (!row || !currentScpiData) {
		return 'Veuillez sélectionner une SCPI';
	}
	if (!row.parts_nue && !row.parts_plein) {
		return "Veuillez sélectionner un mode d'investissement pour chaque SCPI";
	}
	if (row.parts_nue && !row.decote_period) {
		return 'Veuillez renseigner les valeurs des périodes de démembrement pour faire une simulation';
	}
	if (row.parts_nue && row.decote_period > 20) {
		return "La période de démembrement doit être plus courte que la durée de l'investissment";
	}
	if (row.parts_nue && row.decote_period < 3) {
		return 'La période de démembrement doit être supérieure à 3 ans';
	}
	if (!row.parts_nue && !row.parts_plein) {
		return 'Veuillez saisir un nombre de parts dans chaque SCPI';
	}
	if (row.parts_nue && row.parts_nue < currentScpiData?.minNPShares) {
		return `Le minimum de parts pour ${currentScpiData?.name} est de ${currentScpiData?.minNPShares} parts`;
	}
	if (row.parts_plein && row.parts_plein < currentScpiData?.minPPShares) {
		return `Le minimum de parts pour ${currentScpiData?.name} est de ${currentScpiData?.minPPShares} parts`;
	}
	return '';
}

const ScpiTable: FC<ScpiTableProps> = ({ recommendationTable, setRecommendationTable }) => {
	const { data: scpiFunds } = useGetScpiFundsQuery();
	const [getScpiDemembrement] = useLazyGetScpiDemembrementQuery();

	const [selectedScpi, setSelectedScpi] = useState<string | undefined>(undefined);
	const [newRow, setNewRow] = useState<ScpiAllocation | undefined>(undefined);
	const [newRowName, setNewRowName] = useState<string>('-');
	const [error, setError] = useState<string>('');
	const [newRowMode, setNewRowMode] = useState<'np' | 'pp'>('np');
	const [modifiedRow, setModifiedRow] = useState<ScpiAllocation | undefined>(undefined);

	const handleDelete = useCallback(
		(scpiName: string) => {
			if (!scpiName) return;
			const newAllocation = { ...recommendationTable.allocation };
			delete newAllocation[scpiName];

			setRecommendationTable({
				...recommendationTable,
				allocation: newAllocation,
			});
		},
		[recommendationTable, setRecommendationTable],
	);

	const handleAddRow = async () => {
		if (!newRow || newRowName === '-') return;

		const selected = scpiFunds?.find((scpi) => scpi.id === newRowName);
		if (!selected) return;
		const scpiName = selected ? selected.name : '';

		const demembrement = await getScpiDemembrement(selected.id);
		if (!demembrement.data) return;

		const prixPartPP = selected.lastShareValuation;
		const prixPartNP = newRowMode === 'np' ? demembrement.data[newRow.decote_period] * prixPartPP : 1;

		// if modification
		if (
			modifiedRow &&
			modifiedRow.montant_nu + modifiedRow.montant_plein !== newRow.montant_nu + newRow.montant_plein
		) {
			newRow.parts_plein = Math.ceil(newRow.montant_plein / prixPartPP);
			newRow.parts_nue = Math.ceil(newRow.montant_nu / prixPartNP);
		}

		const validationNewRow = {
			...newRow,
			id_airtable: selected.id,
			montant_plein: newRow.parts_plein * prixPartPP,
			montant_nu: newRow.parts_nue * prixPartNP,
		};

		const errorMsg = isNewRowValid(validationNewRow, scpiFunds, newRowName);
		if (error !== '') {
			setError(errorMsg);
			return;
		}

		const updatedAllocation = {
			...recommendationTable.allocation,
			[scpiName]: validationNewRow,
		};

		setRecommendationTable({
			...recommendationTable,
			allocation: updatedAllocation,
		});

		setNewRow(undefined);
		setNewRowName('-');
		setModifiedRow(undefined);
		setError('');
	};

	return (
		<Box>
			<TableContainer bg="white">
				<Table variant="simple" size="sm">
					<Thead style={{ backgroundColor: '#E7E7E7' }}>
						<Tr>
							<Th>Nom</Th>
							<Th>Mode</Th>
							<Th>Montant</Th>
							<Th>Prix/part</Th>
							<Th>Nombre de parts</Th>
							<Th>Démembrement (année)</Th>
						</Tr>
					</Thead>

					<Tbody>
						{recommendationTable &&
							Object.entries(recommendationTable.allocation).map(([scpiName, scpiData]) => (
								<Tr
									key={scpiName}
									bg={selectedScpi === scpiName ? '#E3E8F6' : 'white'}
									_hover={{
										background: '#F7F7F7',
									}}
									onClick={() => {
										setSelectedScpi(scpiName);
									}}
									onDoubleClick={() => {
										setNewRow({
											parts_plein: scpiData.parts_plein,
											parts_nue: scpiData.parts_nue,
											montant_plein: scpiData.montant_plein,
											montant_nu: scpiData.montant_nu,
											id_airtable: scpiData.id_airtable,
											decote_period: scpiData.decote_period,
										});
										setNewRowName(scpiData.id_airtable);
										setNewRowMode(scpiData.parts_nue > 0 ? 'np' : 'pp');
										setModifiedRow(scpiData);
										handleDelete(scpiName);
									}}
								>
									<Td>
										<span
											style={{
												padding: '0.2rem 0.4rem',
												backgroundColor: '#BFDBFE',
												color: '#000',
												borderRadius: '1rem',
											}}
										>
											{scpiName}
										</span>
									</Td>
									<Td>
										<span
											style={{
												padding: '0.2rem 0.4rem',
												backgroundColor: scpiData.parts_nue > 0 ? '#EECFF3' : '#F6AD55',
												color: '#000',
												borderRadius: '1rem',
											}}
										>
											{scpiData.parts_nue > 0 ? 'np' : 'pp'}
										</span>
									</Td>
									<Td isNumeric>{format(',.0f')(scpiData.montant_nu + scpiData.montant_plein)}</Td>
									<Td isNumeric>
										{format(',.2f')(
											(scpiData.montant_nu + scpiData.montant_plein) / (scpiData.parts_nue + scpiData.parts_plein),
										)}
									</Td>
									<Td isNumeric>{scpiData.parts_nue + scpiData.parts_plein}</Td>
									<Td isNumeric>{scpiData.decote_period > 0 ? scpiData.decote_period : '-'}</Td>
								</Tr>
							))}
						{/* --- add row --- */}
						{newRow && (
							<Tr bg="#BFDBFE">
								<Td>
									<Select value={newRowName} onChange={(e) => setNewRowName(e.target.value)} size="xs" variant="filled">
										{scpiFunds?.map((scpi) => (
											<option key={scpi.id} value={scpi.id}>
												{scpi.name}
											</option>
										))}
									</Select>
								</Td>
								<Td>
									<Select
										value={newRowMode}
										onChange={(e) => setNewRowMode(e.target.value as 'np' | 'pp')}
										size="xs"
										variant="filled"
										width={20}
									>
										<option value="np">np</option>
										<option value="pp">pp</option>
									</Select>
								</Td>
								<Td isNumeric>
									<Input
										value={newRow.montant_nu + newRow.montant_plein}
										onChange={(e) => {
											setNewRow({
												...newRow,
												montant_plein: newRowMode === 'pp' ? Number(e.target.value) : 0,
												montant_nu: newRowMode === 'np' ? Number(e.target.value) : 0,
											});
										}}
										size="xs"
										width={20}
										variant="filled"
									/>
								</Td>
								<Td isNumeric>
									<Text>
										{newRow.montant_nu + newRow.montant_plein > 0
											? format(',.2f')(
													(newRow.montant_nu + newRow.montant_plein) / (newRow.parts_nue + newRow.parts_plein),
											  )
											: ''}
									</Text>
								</Td>
								<Td isNumeric>
									<Input
										value={newRowMode === 'np' ? newRow.parts_nue : newRow.parts_plein}
										onChange={(e) =>
											setNewRow({
												...newRow,
												[newRowMode === 'np' ? 'parts_nue' : 'parts_plein']: Number(e.target.value),
												parts_nue: newRowMode === 'np' ? Number(e.target.value) : 0,
												parts_plein: newRowMode === 'pp' ? Number(e.target.value) : 0,
											})
										}
										size="xs"
										width={20}
										variant="filled"
									/>
								</Td>
								<Td isNumeric>
									<Input
										value={newRow.decote_period}
										onChange={(e) => setNewRow({ ...newRow, decote_period: Number(e.target.value) })}
										size="xs"
										width={20}
										variant="filled"
									/>
								</Td>
							</Tr>
						)}
					</Tbody>
					{error !== '' && (
						<Tr>
							<Td colSpan={6} style={{ color: 'red' }}>
								{error}
							</Td>
						</Tr>
					)}
				</Table>
				<HStack justifyContent="space-between" alignItems="center" mt={5}>
					{newRow && (
						<HStack spacing={2}>
							<Text
								colorScheme="yellow"
								onClick={handleAddRow}
								_hover={{ cursor: 'pointer' }}
								p={2}
								outline="1px solid #E2E8F0"
								margin={2}
								bg="#E3E8F6"
							>
								Valider
							</Text>
							<Text
								colorScheme="red"
								onClick={() => {
									setNewRow(undefined);
									setRecommendationTable({
										...recommendationTable,
										allocation: {
											...recommendationTable.allocation,
											[selectedScpi as string]: modifiedRow as ScpiAllocation,
										},
									});
								}}
								_hover={{ cursor: 'pointer' }}
								p={2}
								outline="1px solid #E2E8F0"
								bg="#F7F7F7"
							>
								Annuler
							</Text>
						</HStack>
					)}
					<Text fontSize="sm" color="gray.600" p={2}>
						Showing {Object.entries(recommendationTable.allocation).length} results
					</Text>
					<HStack spacing={2}>
						<Text
							fontSize="sm"
							color="gray.600"
							p={2}
							_hover={{ cursor: 'pointer', color: 'black' }}
							onClick={() => handleDelete(selectedScpi as string)}
						>
							Supprimer ligne
						</Text>
						<Text
							fontSize="large"
							color="gray.600"
							p={2}
							_hover={{ cursor: 'pointer', color: 'black' }}
							onClick={() =>
								newRow
									? handleAddRow()
									: setNewRow({
											parts_plein: 0,
											parts_nue: 0,
											montant_plein: 0,
											montant_nu: 0,
											id_airtable: '',
											decote_period: 0,
									  })
							}
						>
							+
						</Text>
					</HStack>
				</HStack>
			</TableContainer>
		</Box>
	);
};

export default ScpiTable;
