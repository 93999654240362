import { FC } from 'react';
import { Button, Skeleton, Table, TableContainer, Tbody, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react';

import { DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import AddBankAccountModal from 'features/bank-account/AddBankAccountModal';
import BankingDocuments from 'features/bank-account/BankingDocuments';
import { useGetDocumentsQuery } from 'services/document';
import { useGetUserBankAccountsQuery } from 'services/ops/bank-account';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

type BankAccountListProps = {
	email: string;
	onSelect?: (bankId: string) => void;
	selectedBankId?: string | null;
};

const BankAccountList: FC<BankAccountListProps> = ({ email, onSelect, selectedBankId }) => {
	const { isOpen: isOpenAddNewAccount, onOpen: onOpenAddNewAccount, onClose: onCloseAddNewAccount } = useDisclosure();

	const { data: bankAccounts, isFetching: isFetchingUserBanks } = useGetUserBankAccountsQuery({ email });
	const { data: userDocuments, isFetching: isFetchingUserDocuments } = useGetDocumentsQuery({
		email,
		productType: undefined,
		subscriptionId: undefined,
	});

	return (
		<VStack align="start" w="100%" spacing="32px">
			<Skeleton isLoaded={!isFetchingUserBanks || isFetchingUserDocuments} w="100%">
				<TableContainer w="100%">
					<Table variant="simple" size="sm">
						<Thead>
							<Tr>
								<DefaultTh>ID</DefaultTh>
								<DefaultTh>Holder</DefaultTh>
								<DefaultTh>Label</DefaultTh>
								<DefaultTh>BIC</DefaultTh>
								<DefaultTh>IBAN</DefaultTh>
								<DefaultTh>Justificatif</DefaultTh>
								<PermissionDisplayGuard permission={BoPermission.CLIENT_BANK_ACCOUNT_UPDATE}>
									<DefaultTh>Justificatif</DefaultTh>
								</PermissionDisplayGuard>
							</Tr>
						</Thead>

						<Tbody>
							{(bankAccounts ?? []).map((bank) => (
								<Tr
									key={bank.id}
									cursor="pointer"
									onClick={() => onSelect?.(bank.id)}
									bg={selectedBankId === bank.id ? 'green.100' : undefined}
								>
									<DefaultTd>{bank.id}</DefaultTd>
									<DefaultTd>{bank.holder}</DefaultTd>
									<DefaultTd>{bank.label || bank.holder}</DefaultTd>
									<DefaultTd>{bank.BIC}</DefaultTd>
									<DefaultTd>{bank.IBAN}</DefaultTd>
									<BankingDocuments
										bank={bank}
										isFetchingUserDocuments={isFetchingUserDocuments}
										userDocuments={userDocuments}
									/>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Skeleton>

			{/*ADD NEW BANK ACCOUNT*/}
			<PermissionDisplayGuard permission={BoPermission.CLIENT_BANK_ACCOUNT_CREATE}>
				{bankAccounts && <Button onClick={onOpenAddNewAccount}>Ajouter un compte bancaire</Button>}
			</PermissionDisplayGuard>

			{/*MODALS*/}
			{bankAccounts && (
				<AddBankAccountModal isOpen={isOpenAddNewAccount} onClose={onCloseAddNewAccount} clientEmail={email} />
			)}
		</VStack>
	);
};

export default BankAccountList;
