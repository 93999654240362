import { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { isBIOverdue } from 'features/ChangeOverdueDate';
import useSessionStorageState from 'hooks/useSessionStorate';
import { BlockingInstanceWithKyc } from 'services/ops/blocking-instance';
import { BlockingInstance, BlockingInstanceLinkedEntity, BlockingInstanceStatus } from 'types/blocking-instance.type';
import { BOContext, ProductType, SubscriptionStatus } from 'types/global.type';
import { Envelope, InvestDeal } from 'types/invest-deal.type';
import { displayMoney, getUpdatedStatusTime, isNotNone } from 'utils/functions';

import { DefaultTh, SortTh } from './columns/Th';

//
// UTILS
//

const getBlockingInstanceAmount = (
	linkedEntity: BlockingInstanceLinkedEntity | undefined,
	productType: ProductType,
): number | undefined => {
	if (!linkedEntity) return;
	if (
		productType === ProductType.INVEST &&
		linkedEntity.status === SubscriptionStatus.COMPLETED &&
		'amount' in linkedEntity
	)
		return +linkedEntity.amount!;
	if (
		(productType === ProductType.SCPI ||
			productType === ProductType.PE ||
			productType === ProductType.CROWDFUNDING ||
			productType === ProductType.INVEST ||
			productType === ProductType.ART ||
			productType === ProductType.CASH) &&
		'initialDepositAmount' in linkedEntity
	)
		return linkedEntity.initialDepositAmount ? +linkedEntity.initialDepositAmount : undefined;

	return undefined;
};

const getBlockingInstanceProduct = (
	linkedEntity: BlockingInstanceLinkedEntity | undefined,
	productType: ProductType,
) => {
	if (productType === ProductType.INVEST && isNotNone(linkedEntity))
		return (linkedEntity as InvestDeal & { envelope?: Envelope })?.envelope?.type;
	return productType;
};

const getDateDistance = (date: string | undefined) => {
	if (!date) return '?';
	const then = new Date(date);
	const now = new Date();
	const diff = now.getTime() - then.getTime();
	const days = Math.floor((diff / (1000 * 60 * 60 * 24)) * 10) / 10;

	return days;
};

//
// DISPLAY
//

const rowColor = (bi: BlockingInstance, selectedBi?: BlockingInstance): string => {
	if (selectedBi?.id === bi.id) return 'blue.50';
	if (isBIOverdue(bi)) return 'red.50';
	return 'white';
};

//
// SORTING
//

const sortFields = (a: BlockingInstanceWithKyc, b: BlockingInstanceWithKyc, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'createdAt') {
		if (order === 'asc') return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		else return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
	}
	if (orderBy === 'updatedAt') {
		if (order === 'asc') return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
		else return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	}
	if (orderBy === 'statusUpdatedAt') {
		const aStatusUpdatedAt = getUpdatedStatusTime(a)?.getTime() ?? 0;
		const bStatusUpdatedAt = getUpdatedStatusTime(b)?.getTime() ?? 0;
		if (order === 'asc') return aStatusUpdatedAt - bStatusUpdatedAt;
		else return bStatusUpdatedAt - aStatusUpdatedAt;
	}
	if (orderBy === 'sentToClientAt') {
		const asentToClientAt = a.sentToClientAt ? new Date(a.sentToClientAt).getTime() : new Date().getTime();
		const bsentToClientAt = b.sentToClientAt ? new Date(b.sentToClientAt).getTime() : new Date().getTime();
		if (order === 'asc') return asentToClientAt - bsentToClientAt;
		else return bsentToClientAt - asentToClientAt;
	}
	if (orderBy === 'amount') {
		const aAmount = getBlockingInstanceAmount(a.linkedEntity, a.product);
		const bAmount = getBlockingInstanceAmount(b.linkedEntity, b.product);
		if (order === 'asc') return (aAmount || 0) - (bAmount || 0);
		else return (bAmount || 0) - (aAmount || 0);
	}
	return 0;
};

export const blockingInstanceTableColumns = {
	email: {
		name: 'Email',
		get: (bi: BlockingInstanceWithKyc) => bi.user?.email,
	},
	lastName: {
		name: 'Nom',
		get: (bi: BlockingInstanceWithKyc) => bi.user?.kyc?.lastName,
	},
	firstName: {
		name: 'Prénom',
		get: (bi: BlockingInstanceWithKyc) => bi.user?.kyc?.firstName,
	},
	product: {
		name: 'Produit',
		get: (bi: BlockingInstanceWithKyc) => getBlockingInstanceProduct(bi.linkedEntity, bi.product),
	},
	amount: {
		name: 'Montant',
		get: (bi: BlockingInstanceWithKyc) => getBlockingInstanceAmount(bi.linkedEntity, bi.product),
	},
	isBlack: {
		name: 'Black',
		get: (bi: BlockingInstanceWithKyc) => bi.user?.isBlack,
	},
	partner: {
		name: 'Partner',
		get: (bi: BlockingInstanceWithKyc) => bi.partner,
	},
	lifecycle: {
		name: 'Lifecycle',
		get: (bi: BlockingInstanceWithKyc) => bi.lifecycle,
	},
	status: {
		name: 'Statut',
		get: (bi: BlockingInstanceWithKyc) => bi.status,
	},
	hasBeenSentToClient: {
		name: 'Mail Client',
		get: (bi: BlockingInstanceWithKyc) => bi.hasBeenSentToClient,
	},
	hasBeenSentToPartner: {
		name: 'Mail Partner',
		get: (bi: BlockingInstanceWithKyc) => bi.hasBeenSentToPartner,
	},
	createdAt: {
		name: 'Créé le',
		get: (bi: BlockingInstanceWithKyc) => new Date(bi.createdAt),
	},
	updatedAt: {
		name: 'MAJ le',
		get: (bi: BlockingInstanceWithKyc) => new Date(bi.updatedAt),
	},
	statusUpdatedAt: {
		name: 'MAJ Statut',
		get: (bi: BlockingInstanceWithKyc) => getUpdatedStatusTime(bi),
	},
	sentToClientAt: {
		name: 'Envoyé le',
		get: (bi: BlockingInstanceWithKyc) => getDateDistance(bi.sentToClientAt) + ' jours',
	},
	assignedOpsEmail: {
		name: 'Respo',
		get: (bi: BlockingInstanceWithKyc) => bi?.opsProperties?.assignedOpsEmail?.split('@')[0].replace('.', ' '),
	},
};

//
// COMPONENT
//

type BlockingInstancesTableProps = {
	context: Extract<BOContext, 'client' | 'blocking-instance'>;
	blockingInstances: BlockingInstanceWithKyc[];
	selectedBlockingInstances?: BlockingInstanceWithKyc;
	onClick: (c: BlockingInstanceWithKyc) => void;
	pageSize: number;
};

const initialState = {
	pageIndex: 0,
	selectedStatus: 'ALL' as const,
	sortBy: 'updatedAt',
	sortDirection: 'desc' as const,
};

const BlockingInstancesTable = ({
	context,
	blockingInstances,
	selectedBlockingInstances,
	onClick,
	pageSize,
}: BlockingInstancesTableProps) => {
	const [pageIndex, setPageIndex] = useSessionStorageState<number>(
		`${context}_BLOCKING_INSTANCE_PAGE_INDEX`,
		initialState.pageIndex,
	);
	const [selectedStatus, setSelectedStatus] = useSessionStorageState<BlockingInstanceStatus | 'ALL'>(
		`${context}_BLOCKING_INSTANCE_SELECTED_STATUS`,
		initialState.selectedStatus,
	);
	const [sortBy, setSortBy] = useSessionStorageState(`${context}_BLOCKING_INSTANCE_SORT_BY`, initialState.sortBy);
	const [sortDirection, setSortDirection] = useSessionStorageState<'asc' | 'desc'>(
		`${context}_BLOCKING_INSTANCE_SORT_DIRECTION`,
		initialState.sortDirection,
	);

	const nbInstances = useMemo(
		() => blockingInstances.filter((s) => (selectedStatus === 'ALL' ? true : s.status === selectedStatus)).length,
		[blockingInstances, selectedStatus],
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	const statuses = ['ALL', ...Object.values(BlockingInstanceStatus)];

	const initialIndex = statuses.findIndex((status) => status === selectedStatus);

	return (
		<Box>
			<Tabs
				variant="enclosed"
				isFitted
				isLazy
				bg="white"
				index={initialIndex}
				onChange={(index) => {
					setSelectedStatus(statuses[index] as BlockingInstanceStatus);
					setPageIndex(0);
				}}
			>
				{context === 'blocking-instance' && (
					<TabList
						h="50px"
						overflowY="hidden"
						borderWidth="2px"
						borderColor="gray.100"
						sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}
					>
						{statuses.map((status) => (
							<Tab
								key={status}
								_selected={{
									borderRadius: '0px',
									borderWidth: '2px',
									borderColor: 'gray.400',
								}}
							>
								{status}
							</Tab>
						))}
					</TabList>
				)}
				<TabPanels>
					{statuses.map((status) => (
						<TabPanel key={status} px="0px" py="0px">
							<TableContainer bg="white">
								<Table variant="simple" size="sm">
									<Thead>
										<Tr>
											<DefaultTh>{blockingInstanceTableColumns.email.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.lastName.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.firstName.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.product.name}</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="amount"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{blockingInstanceTableColumns.amount.name}
											</SortTh>
											<DefaultTh>{blockingInstanceTableColumns.isBlack.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.partner.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.lifecycle.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.status.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.hasBeenSentToClient.name}</DefaultTh>
											<DefaultTh>{blockingInstanceTableColumns.hasBeenSentToPartner.name}</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="createdAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{blockingInstanceTableColumns.createdAt.name}
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="updatedAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{blockingInstanceTableColumns.updatedAt.name}
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="statusUpdatedAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{blockingInstanceTableColumns.statusUpdatedAt.name}
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="sentToClientAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{blockingInstanceTableColumns.sentToClientAt.name}
											</SortTh>
											<DefaultTh>{blockingInstanceTableColumns.assignedOpsEmail.name}</DefaultTh>
										</Tr>
									</Thead>

									<Tbody>
										{blockingInstances
											.filter((s) => (status === 'ALL' ? true : s.status === status))
											.filter((s) => (context === 'client' ? s.status !== BlockingInstanceStatus.CLOSED : true))
											.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
											.slice(pageIndex, pageIndex + pageSize)
											.map((bi) => (
												<ConditionalLink
													key={bi.id}
													style={{ display: 'contents' }}
													to={`/ops/super/blocking-instance/${bi.id}`}
													shouldrenderLink={bi.status !== BlockingInstanceStatus.OPEN}
												>
													<Tr
														cursor="pointer"
														bg={rowColor(bi, selectedBlockingInstances)}
														onClick={() => {
															if (bi.status === BlockingInstanceStatus.OPEN && onClick) onClick(bi);
														}}
													>
														<DefaultTd>{blockingInstanceTableColumns.email.get(bi)}</DefaultTd>
														<DefaultTd>{blockingInstanceTableColumns.lastName.get(bi)}</DefaultTd>
														<DefaultTd>{blockingInstanceTableColumns.firstName.get(bi)}</DefaultTd>
														<DefaultTd>{blockingInstanceTableColumns.product.get(bi)}</DefaultTd>
														<DefaultTd>{displayMoney(blockingInstanceTableColumns.amount.get(bi))}</DefaultTd>
														<BoolTd value={blockingInstanceTableColumns.isBlack.get(bi)} />
														<DefaultTd>{blockingInstanceTableColumns.partner.get(bi)}</DefaultTd>
														<DefaultTd>{blockingInstanceTableColumns.lifecycle.get(bi)}</DefaultTd>
														<DefaultTd>{blockingInstanceTableColumns.status.get(bi)}</DefaultTd>
														<BoolTd value={blockingInstanceTableColumns.hasBeenSentToClient.get(bi)} />
														<BoolTd value={blockingInstanceTableColumns.hasBeenSentToPartner.get(bi)} />
														<DateTd value={blockingInstanceTableColumns.createdAt.get(bi)} />
														<DateTd value={blockingInstanceTableColumns.updatedAt.get(bi)} />
														<DateTd value={blockingInstanceTableColumns.statusUpdatedAt.get(bi)} />
														<DefaultTd>{blockingInstanceTableColumns.sentToClientAt.get(bi)}</DefaultTd>
														<DefaultTd>{blockingInstanceTableColumns.assignedOpsEmail.get(bi)}</DefaultTd>
													</Tr>
												</ConditionalLink>
											))}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" mb="16px" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {nbInstances} entries{' '}
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() =>
							setPageIndex(pageIndex === 0 ? Math.floor(nbInstances / pageSize) * 10 : pageIndex - pageSize)
						}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= nbInstances ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default BlockingInstancesTable;
