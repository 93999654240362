import { FC, useCallback, useState } from 'react';
import { Checkbox, HStack, Input, Text, VStack } from '@chakra-ui/react';

import BasicCard from 'components/BasicCard';
import useThemedToast from 'hooks/useThemedToast';
import { useLazyGetIsinAumQuery } from 'services/ops/aum';
import { displayMoney } from 'utils/functions';

const IsinAumCard: FC = () => {
	const toast = useThemedToast();

	const [computeIsinAum, { isFetching: isIsinFetching }] = useLazyGetIsinAumQuery();

	const [isin, setIsin] = useState('');
	const [excludeNegative, setExcludeNegative] = useState(true);
	const [aum, setAum] = useState<number | null>(null);

	const handleComputeIsinAum = useCallback(
		() =>
			computeIsinAum({ isin, excludeNegative })
				.unwrap()
				.then(({ totalAum }) => setAum(totalAum))
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message })),
		[computeIsinAum, excludeNegative, isin, toast],
	);

	return (
		<BasicCard
			title="Collecte ISIN"
			description="Obtenir la collecte totale sur un ISIN"
			buttonText="Obtenir"
			isLoading={isIsinFetching}
			onClick={handleComputeIsinAum}
			body={
				<VStack align="start">
					<Checkbox isChecked={excludeNegative} onChange={(e) => setExcludeNegative(e.target.checked)}>
						Exclure les valeurs négatives
					</Checkbox>
					<Input placeholder="ISIN" value={isin} onChange={(e) => setIsin(e.target.value)} />
					{aum && (
						<HStack>
							<Text variant="Text-M-Bold">Collecte totale :</Text>
							<Text>{displayMoney(aum?.toString())}</Text>
						</HStack>
					)}
				</VStack>
			}
		/>
	);
};

export default IsinAumCard;
