import { useNavigate } from 'react-router-dom';
import { Divider, Heading, Text, VStack, Wrap } from '@chakra-ui/react';

import BasicCard from 'components/BasicCard';
import ArtAumCard from 'pages/ops/art';
import CrowdfundingAumCard from 'pages/ops/crowd';
import IsinAumCard from 'pages/ops/isin';
import KpiStatusCard from 'pages/ops/kpi';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

import {
	CashbackInvestCard,
	CashbackScpiCard,
	LegacyCashbackInvestCard,
	LegacyCashbackScpiPeCard,
} from './ops/cashback';

const Home = () => {
	const navigate = useNavigate();

	return (
		<VStack w="100%" spacing="32px" px="48px" py="32px">
			<PermissionDisplayGuard permission={BoPermission.CLIENT_READ}>
				<VStack w="100%" spacing="20px" align="start">
					<Heading size="lg">Tools OPS</Heading>
					<Wrap w="100%" spacing="40px" shouldWrapChildren>
						<BasicCard
							title="Super Tool"
							description="Un super outil pour les gouverner tous"
							body={
								<Text w="100%" textAlign="center" fontSize="4xl">
									🧝‍♂️ 🧝 🧝‍♀️
								</Text>
							}
							buttonText="Démarrer"
							onClick={() => navigate('/ops/super')}
						/>

						<PermissionDisplayGuard permission={BoPermission.ALERTS_SYSTEM_READ}>
							<BasicCard
								title="Alertes"
								description="Visualisation des alertes sur différents services de l'app."
								buttonText="Démarrer"
								onClick={() => navigate('/ops/alertes')}
							/>
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.DEAL_LINK_INVEST_SUBSCRIPTION_UPDATE}>
							<BasicCard
								title="Rattachement contrat"
								description="Rattacher un contrat invest pour un client manuel."
								buttonText="Démarrer"
								onClick={() => navigate('/ops/rattachement-subscriptions')}
							/>
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_KPI_READ}>
							<KpiStatusCard />
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_CASHBACK_READ}>
							<BasicCard
								buttonText="Ouvrir"
								onClick={() => navigate('/ops/sponsorship')}
								title="Primes Parrainages"
								description="Génération des extracts et paiement des primes de parrainages pour les clients concernés"
							/>
						</PermissionDisplayGuard>

						{/* TODO - Changer la permission */}
						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_CASHBACK_READ}>
							<BasicCard
								title="Remboursements Offres promotionnelles"
								description="Permet de payer les offres promotionnelles (incluant cashback / parrainage)"
								buttonText="Ouvrir"
								onClick={() => navigate('/ops/offers')}
							/>
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_CASHBACK_READ}>
							<CashbackInvestCard />
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_CASHBACK_READ}>
							<CashbackScpiCard />
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_CASHBACK_READ}>
							<LegacyCashbackScpiPeCard />
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_CASHBACK_READ}>
							<LegacyCashbackInvestCard />
						</PermissionDisplayGuard>
					</Wrap>
				</VStack>
				<Divider />
			</PermissionDisplayGuard>

			<PermissionDisplayGuard permission={BoPermission.PROPALE_READ}>
				<VStack w="100%" spacing="20px" align="start">
					<Heading size="lg">Tools CPM</Heading>
					<Wrap w="100%" spacing="40px" shouldWrapChildren>
						<BasicCard
							title="Cross Asset Backtest"
							description="Outil de simulaton de portefeuille avancé pour la création de propositions d'investissement"
							buttonText="Démarrer"
							onClick={() => navigate('/cpm/cross-asset-backtests')}
						/>
						<BasicCard
							title="Propale Produit"
							description="Création de propositions d'investissements (pas de portefeuille custom)"
							buttonText="Démarrer"
							onClick={() => navigate('/cpm/propale-produit')}
						/>
						<BasicCard
							title="Propale Fiscalité"
							description="Création de propositions d'investissement pour la période fiscale"
							buttonText="Démarrer"
							onClick={() => navigate('/cpm/propale-fiscalite')}
						/>
						<BasicCard
							title="Propale SCPI - Direct"
							description="Création de propositions d'investissement pour les SCPI en Direct"
							buttonText="Démarrer"
							onClick={() => navigate('/cpm/scpi-direct')}
						/>
						<BasicCard
							title="Propale SCPI - Crédit"
							description="Création de propositions d'investissement pour les SCPI à crédit"
							buttonText="Démarrer"
							onClick={() => navigate('/cpm/propale-scpi-credit')}
						/>
					</Wrap>
				</VStack>
				<Divider />
			</PermissionDisplayGuard>

			<PermissionDisplayGuard permission={BoPermission.REBALANCING_READ}>
				<VStack w="100%" spacing="20px" align="start">
					<Heading size="lg">Tools QIS</Heading>
					<Wrap w="100%" spacing="40px" shouldWrapChildren>
						<BasicCard
							title="Arbitrage Invest"
							description="Mise a jour manuelle des contrats à arbitrer selon les critères de turnover"
							buttonText="Démarrer"
							onClick={() => navigate('/qis/rebalancing')}
						/>
						<PermissionDisplayGuard permission={BoPermission.CLIENT_AUTOLOGIN_READ}>
							<BasicCard
								title="Autologin"
								description="Outil pour se connecter sur le compte d'un client"
								buttonText="Démarrer"
								onClick={() => navigate('/qis/autologin')}
							/>
						</PermissionDisplayGuard>
					</Wrap>
				</VStack>
				<Divider />
			</PermissionDisplayGuard>

			<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_ISIN_READ}>
				<VStack w="100%" spacing="20px" align="start">
					<Heading size="lg">Tools Partnership</Heading>
					<Wrap w="100%" spacing="40px" shouldWrapChildren>
						<BasicCard
							title="Copilot Art"
							description="Extraction d'informations d'une note d'investissement d'art avec export vers Airtable"
							buttonText="Démarrer"
							onClick={() => navigate('/partnership/copilotart')}
						/>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_ISIN_READ}>
							<IsinAumCard />
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_ISIN_READ}>
							<CrowdfundingAumCard />
						</PermissionDisplayGuard>

						<PermissionDisplayGuard permission={BoPermission.OPS_TOOL_ISIN_READ}>
							<ArtAumCard />
						</PermissionDisplayGuard>
					</Wrap>
				</VStack>
				<Divider />
			</PermissionDisplayGuard>

			<PermissionDisplayGuard permission={BoPermission.PHOENIX_READ}>
				<VStack w="100%" spacing="20px" align="start">
					<Heading size="lg">Tools Phoenix</Heading>
					<Wrap w="100%" spacing="40px" shouldWrapChildren>
						<BasicCard
							title="Création de compte"
							description="Permet d'onboarder un client Phoenix"
							buttonText="Démarrer"
							onClick={() => navigate('/phoenix/create-account')}
						/>
					</Wrap>
				</VStack>
				<Divider />
			</PermissionDisplayGuard>
		</VStack>
	);
};

export default Home;
