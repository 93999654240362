import { FC, useCallback, useMemo } from 'react';
import { DropEvent, DropzoneOptions, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Box, Button, Card, Text, VStack } from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';

type FilePickerProps = {
	shouldShowSelectedFiles: boolean;
	shouldShowSelectButton: boolean;
	files?: File | File[] | null;
	customFormatDisplay?: string;
	buttonComponent?: JSX.Element;
};

const truncateLongFilename = (f: string) => (f.length > 30 ? `${f.substring(0, 30)}...` : f);

const FilePicker: FC<FilePickerProps & DropzoneOptions> = ({
	files,
	shouldShowSelectButton,
	shouldShowSelectedFiles,
	customFormatDisplay,
	buttonComponent,
	onDrop,
	maxSize = 10_000_000,
	...options
}) => {
	const toast = useThemedToast();

	const errorMessagesMap = useMemo(
		() => ({
			[ErrorCode.FileInvalidType]: 'Type de fichier invalide. Veuillez fournir un fichier autorisé',
			[ErrorCode.FileTooLarge]: `Fichier trop volumineux. La taille maximale est de ${maxSize / 1_000_000} Mo.`,
			[ErrorCode.FileTooSmall]: 'Fichier trop petit.',
			[ErrorCode.TooManyFiles]: 'Nombre de fichiers dépassé. Veuillez respecter la limite autorisée.',
		}),
		[maxSize],
	);

	const handleRejections = useCallback(
		(rejectedFiles: FileRejection[]) => {
			const rejectionMessages = rejectedFiles.map(({ file, errors }) => {
				const detailedErrors = errors
					.map((error) => errorMessagesMap[error.code as ErrorCode] || 'Erreur inconnue')
					.join(', ');
				return `${file.name}: ${detailedErrors}`;
			});

			toast({
				title: 'Fichiers incorrects',
				description: rejectionMessages.join('\n'),
				status: 'error',
			});
		},
		[errorMessagesMap, toast],
	);

	const handleOnDrop = useCallback(
		(acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
			if (rejectedFiles.length > 0) {
				handleRejections(rejectedFiles); // Handle rejections internally
			}

			if (acceptedFiles.length > 0 && onDrop) onDrop(acceptedFiles, rejectedFiles, event); // Pass only valid files to the parent
		},
		[handleRejections, onDrop],
	);

	const { getRootProps, getInputProps } = useDropzone({
		minSize: 1, //  block empty files
		maxSize: maxSize,
		maxFiles: 1, // one file only
		accept: '.png,.jpg,.pdf,.jpeg', // https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/accept
		multiple: false, // prevent drag and drop of multiple files
		onDrop: handleOnDrop, // ondrop with rejections handling
		...options,
	});

	const formattedFileNames = useMemo(() => {
		if (!shouldShowSelectButton || !files) return [];
		if (Array.isArray(files)) return files.map((f) => truncateLongFilename(f.name));
		return [truncateLongFilename(files.name)];
	}, [files, shouldShowSelectButton]);

	return (
		<VStack {...getRootProps()} cursor="pointer" position="relative" spacing="12px" align="start">
			{/* input storing the file */}
			<input type="file" {...getInputProps()} />

			{/* button to add a file */}
			{shouldShowSelectButton && <>{buttonComponent ?? <Button justifySelf="end">Ajouter une pièce</Button>}</>}

			{/* green box listing the selected */}
			{shouldShowSelectedFiles && (
				<Card borderRadius="10px" bgColor="rgb(218, 255, 228, 0.5)" border="1px dashed green" h="100%">
					<Box p="12px">
						{formattedFileNames.length > 1 ? (
							<VStack w="100%" align="start">
								<Text>Fichiers ajoutés :</Text>
								{formattedFileNames.map((f) => (
									<Text key={f}>- {f}</Text>
								))}
							</VStack>
						) : (
							<Text>Fichier ajouté : {formattedFileNames}</Text>
						)}
					</Box>
				</Card>
			)}
		</VStack>
	);
};

export default FilePicker;
