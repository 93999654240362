import { FC, ReactNode, useMemo } from 'react';
import { ChatIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Thead,
	Tooltip,
	Tr,
} from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, ButtonTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh, SortTh } from 'components/tables/columns/Th';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useSessionStorageState from 'hooks/useSessionStorate';
import { AllDeal } from 'services/deal';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BOContext, LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { emailToOps, opsDisplayName } from 'types/ops-list.type';
import {
	getDealAmount,
	getDealLegalEntity,
	getDealMoralPersonDenomination,
	getDealOnboardingCompletionDate,
	getDealOpsProcess,
	getDealProductDetails,
	isCallDue,
} from 'utils/dealInfo';
import { displayMoney, getUpdatedStatusTime, isNone } from 'utils/functions';

//
// DISPLAY
//

const rowColor = (deal: AllDeal, selectedDeal?: AllDeal, statuses?: Record<string, StatusLabel>): string => {
	if (selectedDeal?.id === deal.id) return 'blue.50';
	if (isNone(statuses)) return 'white';
	if (isDealOverdue(deal, statuses)) return 'red.50';
	return 'white';
};

//
// SORTING
//

const sortFields = (a: AllDeal, b: AllDeal, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'dealAmount') {
		const aAmount = +(dealTableColumns.dealAmount.get(a) ?? 0);
		const bAmount = +(dealTableColumns.dealAmount.get(b) ?? 0);
		if (order === 'asc') return aAmount - bAmount;
		else return bAmount - aAmount;
	}
	if (orderBy === 'statusUpdatedAt') {
		const aDate = dealTableColumns.statusUpdatedAt.get(a);
		const bDate = dealTableColumns.statusUpdatedAt.get(b);
		const aTime = aDate ? new Date(aDate).getTime() : 0;
		const bTime = bDate ? new Date(bDate).getTime() : 0;
		if (order === 'asc') return aTime - bTime;
		else return bTime - aTime;
	}
	if (orderBy === 'status') {
		const aStatus = a.status;
		const bStatus = b.status;
		const statuses = Object.keys(SubscriptionStatus);
		if (order === 'asc') return statuses.indexOf(aStatus) - statuses.indexOf(bStatus);
		else return statuses.indexOf(bStatus) - statuses.indexOf(aStatus);
	}
	return 0;
};

//
// UTILS
//

// dirty trick to just put link over regular td, with no button inside. https://stackoverflow.com/a/6393863/10889054
const TdLink = ({
	deal,
	shouldrenderLink,
	children,
	context,
}: {
	deal: AllDeal;
	shouldrenderLink: boolean;
	children: ReactNode;
	context: DealsTableProps['context'];
}) => (
	<ConditionalLink
		style={{ display: 'contents' }}
		to={`/ops/super/${context === 'subscription' ? 'subscription' : 'deal'}/${deal.id}?productType=${
			deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER' ? 'TRANSFER' : deal.productType
		}`}
		shouldrenderLink={shouldrenderLink}
		children={children}
	/>
);

//
// COMPONENT
//

export const dealTableColumns = {
	email: {
		name: 'Email',
		get: (deal: AllDeal) => deal.user?.email,
	},
	lastName: {
		name: 'Nom',
		get: (deal: AllDeal) => deal.user?.kyc?.lastName,
	},
	firstName: {
		name: 'Prénom',
		get: (deal: AllDeal) => deal.user?.kyc?.firstName,
	},
	isBlack: {
		name: 'Black',
		get: (deal: AllDeal) => deal.user?.isBlack,
	},
	isMoralPerson: {
		name: 'PM',
		get: (deal: AllDeal) => getDealLegalEntity(deal) === LegalEntity.MORAL,
	},
	moralPersonDenomination: {
		name: 'Société',
		get: (deal: AllDeal) => getDealMoralPersonDenomination(deal),
	},
	productDetail: {
		name: 'Produit',
		get: (deal: AllDeal) => getDealProductDetails(deal),
	},
	partner: {
		name: 'Partenaire',
		get: (deal: AllDeal) => deal.partner,
	},
	assignedOps: {
		name: 'OPS',
		get: (deal: AllDeal) => opsDisplayName[emailToOps[deal.opsProperties?.assignedOpsEmail ?? '']],
	},
	commentsCount: {
		name: 'Commentaires',
		get: (deal: AllDeal) => deal.opsProperties?.comments?.length,
	},
	opsProcess: {
		name: 'Envoi',
		get: (deal: AllDeal) => getDealOpsProcess(deal),
	},
	dealAmount: {
		name: 'Montant',
		get: (deal: AllDeal) => getDealAmount(deal),
	},
	status: {
		name: 'Statut',
		get: (deal: AllDeal) => deal.status,
	},
	didInitialDeposit: {
		name: 'CFCAL 1er deposit',
		get: (deal: AllDeal) => (deal.productType === ProductType.CASH ? deal.didInitialDeposit : undefined),
	},
	statusUpdatedAt: {
		name: 'MAJ Statut le',
		get: (deal: AllDeal) => getUpdatedStatusTime(deal),
	},
	statusCompletedAt: {
		name: 'Complété le',
		get: (deal: AllDeal) => getDealOnboardingCompletionDate(deal),
	},
	hasBlockingInstance: {
		name: 'Instance',
		get: (deal: AllDeal) => deal.hasBlockingInstance,
	},
	isCallDue: {
		name: 'Call ToDo',
		get: (deal: AllDeal) => isCallDue(deal),
	},
};

const initialState = {
	pageIndex: 0,
	selectedStatus: 'ALL' as const,
	sortBy: 'statusUpdatedAt',
	sortDirection: 'desc' as const,
};

type DealsTableProps = {
	context: Extract<BOContext, 'client' | 'subscription' | 'deal' | 'blocking-instance'>;
	deals: AllDeal[];
	selectedDeal?: AllDeal;
	onClick?: (c: AllDeal) => void;
	pageSize: number;
	productTypeStatuses?: Record<string, StatusLabel>;
};

const DealsTable: FC<DealsTableProps> = ({ deals, selectedDeal, onClick, pageSize, context, productTypeStatuses }) => {
	const [pageIndex, setPageIndex] = useSessionStorageState<number>(
		`${context}_DEAL_PAGE_INDEX`,
		initialState.pageIndex,
	);
	const [selectedStatus, setSelectedStatus] = useSessionStorageState<SubscriptionStatus | 'ALL'>(
		`${context}_DEAL_SELECTED_STATUS`,
		initialState.selectedStatus,
	);
	const [sortBy, setSortBy] = useSessionStorageState(`${context}_DEAL_SORT_BY`, initialState.sortBy);
	const [sortDirection, setSortDirection] = useSessionStorageState<'asc' | 'desc'>(
		`${context}_DEAL_SORT_DIRECTION`,
		initialState.sortDirection,
	);

	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();

	const nbDeals = useMemo(
		() => deals.filter((s) => (selectedStatus === 'ALL' ? true : s.status === selectedStatus)).length,
		[deals, selectedStatus],
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	const statuses = [
		'ALL',
		...Object.values(SubscriptionStatus).filter((status) => status !== SubscriptionStatus.PARTNER_TREATMENT),
	];
	const initialIndex = statuses.findIndex((status) => status === selectedStatus);

	return (
		<Box>
			<NextStatusModal />

			<Tabs
				variant="enclosed"
				isFitted
				isLazy
				bg="white"
				index={initialIndex}
				onChange={(index) => {
					setSelectedStatus(statuses[index] as SubscriptionStatus);
					setPageIndex(0);
				}}
			>
				{context === 'deal' && (
					<TabList
						h="50px"
						overflowY="hidden"
						borderWidth="2px"
						borderColor="gray.100"
						sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}
					>
						{statuses.map((status) => (
							<Tab
								key={status}
								_selected={{
									borderRadius: '0px',
									borderWidth: '2px',
									borderColor: 'gray.400',
								}}
							>
								{status}
							</Tab>
						))}
					</TabList>
				)}

				<TabPanels>
					{statuses.map((status) => (
						<TabPanel key={status} px="0px" py="0px">
							<TableContainer bg="white">
								<Table variant="simple" size="sm">
									<Thead>
										<Tr>
											<DefaultTh>{dealTableColumns.email.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.lastName.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.firstName.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.isBlack.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.isMoralPerson.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.moralPersonDenomination.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.productDetail.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.partner.name}</DefaultTh>
											<DefaultTh hide={context === 'subscription'}>{dealTableColumns.assignedOps.name}</DefaultTh>
											<DefaultTh>
												<Tooltip label="Nombre de commentaires">
													<ChatIcon />
												</Tooltip>
											</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="dealAmount"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{dealTableColumns.dealAmount.name}
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="status"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{dealTableColumns.status.name}
											</SortTh>
											<DefaultTh>Changer statut</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="statutsUpdatedAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												{dealTableColumns.statusUpdatedAt.name}
											</SortTh>
											<DefaultTh>{dealTableColumns.opsProcess.name}</DefaultTh>
											<DefaultTh hide={context === 'subscription'}>{dealTableColumns.didInitialDeposit.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.statusCompletedAt.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.hasBlockingInstance.name}</DefaultTh>
											<DefaultTh>{dealTableColumns.isCallDue.name}</DefaultTh>
										</Tr>
									</Thead>

									<Tbody>
										{deals
											.filter((s) => (status === 'ALL' ? true : s.status === status))
											.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
											.slice(pageIndex, pageIndex + pageSize)
											.map((deal) => (
												<Tr
													key={deal.id}
													cursor="pointer"
													bg={rowColor(deal, selectedDeal, productTypeStatuses)}
													onClick={() => (onClick ? onClick(deal) : undefined)}
												>
													<TdLink deal={deal} shouldrenderLink={!onClick} context={context}>
														<DefaultTd>{dealTableColumns.email.get(deal)}</DefaultTd>
														<DefaultTd>{dealTableColumns.lastName.get(deal)}</DefaultTd>
														<DefaultTd>{dealTableColumns.firstName.get(deal)}</DefaultTd>
														<BoolTd value={dealTableColumns.isBlack.get(deal)} />
														<BoolTd value={dealTableColumns.isMoralPerson.get(deal)} />
														<DefaultTd>{dealTableColumns.moralPersonDenomination.get(deal)}</DefaultTd>
														<DefaultTd>{dealTableColumns.productDetail.get(deal)}</DefaultTd>
														<DefaultTd>{dealTableColumns.partner.get(deal)}</DefaultTd>
														<DefaultTd hideCell={context === 'subscription'}>
															{dealTableColumns.assignedOps.get(deal)}
														</DefaultTd>
														<DefaultTd>{dealTableColumns.commentsCount.get(deal)}</DefaultTd>
														<DefaultTd>{displayMoney(dealTableColumns.dealAmount.get(deal))}</DefaultTd>
														<DefaultTd>{dealTableColumns.status.get(deal)}</DefaultTd>
													</TdLink>
													<ButtonTd
														onClick={(event) => {
															event.stopPropagation();
															handleOpenNextStatusModal(deal);
														}}
														buttonStyleProps={{
															isDisabled:
																(context === 'subscription' &&
																	['mail', 'api'].includes(dealTableColumns.opsProcess.get(deal)!)) ||
																[SubscriptionStatus.COMPLETED, SubscriptionStatus.TERMINATED].includes(deal.status) ||
																deal.productType === ProductType.CRYPTO,
															w: '100%',
															fontSize: '14px',
															h: '20px',
														}}
													>
														Suivant
													</ButtonTd>
													<TdLink deal={deal} shouldrenderLink={!onClick} context={context}>
														<DateTd value={dealTableColumns.statusCompletedAt.get(deal)} />
														<DefaultTd>{dealTableColumns.opsProcess.get(deal)}</DefaultTd>
														<BoolTd
															hideCell={context === 'subscription'}
															value={dealTableColumns.didInitialDeposit.get(deal)}
														/>
														<DateTd value={dealTableColumns.statusUpdatedAt.get(deal)} />
														<BoolTd value={dealTableColumns.hasBlockingInstance.get(deal)} />
														<BoolTd value={dealTableColumns.isCallDue.get(deal)} />
													</TdLink>
												</Tr>
											))}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {nbDeals} entries
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex === 0 ? Math.floor(nbDeals / pageSize) * 10 : pageIndex - pageSize)}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= nbDeals ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default DealsTable;
