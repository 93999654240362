import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Radio, RadioGroup } from '@chakra-ui/react';

import { BackTestFrequency, backtestFrequencyLabel, CrossAssetBacktestBody } from 'services/cpm/cross-asset-backtest';

const BackTestDetails: FC = () => {
	const { control } = useFormContext<CrossAssetBacktestBody>();

	return (
		<FormControl>
			<FormLabel size="sm">Fréquence du backtest</FormLabel>
			<Controller
				name="backtestFrequency"
				control={control}
				render={({ field }) => (
					<RadioGroup {...field}>
						<HStack>
							{Object.values(BackTestFrequency).map((type) => (
								<Radio key={type} value={type}>
									{backtestFrequencyLabel[type]}
								</Radio>
							))}
						</HStack>
					</RadioGroup>
				)}
			/>
		</FormControl>
	);
};

export default BackTestDetails;
