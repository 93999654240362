import { Box, FormLabel, HStack, Radio, RadioGroup, Select, Text, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { ScpiFundData } from 'pages/cpm/product-propale/propaleData.type';
import { ScpiProjectType } from 'types/airtable/global.airtable.type';
import { LegalEntity } from 'types/global.type';

import AddCommentPropale from './common/AddCommentPropale';

const calculateAmount = (
	f: ScpiFundData,
	nbShare: number,
	projectType: ScpiProjectType,
	demembrement?: number,
): number => {
	const currentDemembrement =
		projectType === 'PLEINE_PROPRIETE' ? 1 : demembrement ? f.demembrementData![demembrement] : 1;
	return f.fund.lastShareValuation * nbShare! * currentDemembrement;
};

type ScpiFundCardProps = {
	data: ScpiFundData;
	editData: React.Dispatch<React.SetStateAction<ScpiFundData[]>>;
};

export const ScpiFundCard = ({ data, editData }: ScpiFundCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack w="100%">
			<Box flex="1">
				<FormLabel fontSize="sm">Vous souhaitez investir en tant que:</FormLabel>
				<RadioGroup
					value={data.legalEntity}
					onChange={(legal) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, legalEntity: legal as LegalEntity }].sort((a, b) => a.id - b.id);
						});
					}}
				>
					<HStack>
						<Radio size="sm" value={LegalEntity.PHYSICAL}>
							Personne physique
						</Radio>
						<Radio size="sm" value={LegalEntity.MORAL}>
							Personne morale
						</Radio>
					</HStack>
				</RadioGroup>
			</Box>

			<Box flex="1">
				<FormLabel fontSize="sm">Vous souhaitez investir:</FormLabel>
				<RadioGroup
					value={data.projectType}
					onChange={(projectType) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [
								...newFunds,
								{
									...data,
									projectType: projectType as ScpiProjectType,
									initialDepositAmount: calculateAmount(
										data,
										data.nbShare!,
										projectType as ScpiProjectType,
										data.demembrementDuration,
									),
								},
							].sort((a, b) => a.id - b.id);
						});
					}}
				>
					<HStack>
						<Radio size="sm" value="PLEINE_PROPRIETE">
							Pleine propriété
						</Radio>
						<Radio size="sm" value="NUE_PROPRIETE">
							Nue-propriété
						</Radio>
					</HStack>
				</RadioGroup>
			</Box>
		</HStack>

		<HStack w="100%">
			<Box flex="1">
				<FormLabel fontSize="sm">Montant</FormLabel>
				<Text fontWeight="bold" fontSize="lg">
					{Math.ceil(data.initialDepositAmount * 100) / 100 + '€'}
					{data.projectType === 'PLEINE_PROPRIETE' && (
						<Text as="span" fontSize="sm" fontWeight="normal" ml="8px">
							(ticket d'entrée a <Text as="span">{data.fund.ticket}€</Text>)
						</Text>
					)}
				</Text>
			</Box>

			<Box flex="1">
				<FormLabel fontSize="sm">Nombre de parts</FormLabel>
				<NumberInputWithStepper
					step={1}
					min={Math.ceil(data.fund.ticket / data.fund.lastShareValuation)}
					value={data.nbShare}
					onChange={(nbShare) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [
								...newFunds,
								{
									...data,
									nbShare: +nbShare,
									initialDepositAmount: calculateAmount(data, +nbShare, data.projectType!, data.demembrementDuration),
								},
							].sort((a, b) => a.id - b.id);
						});
					}}
				/>
			</Box>

			{data.projectType === 'NUE_PROPRIETE' && data.demembrementData && (
				<Box flex="1">
					<FormLabel fontSize="sm">Durée démembrement</FormLabel>
					<Select
						flex="2"
						value={data.demembrementDuration}
						onChange={(demembrement) => {
							editData((old) => {
								const newFunds = [...old];
								newFunds.splice(newFunds.indexOf(data), 1);
								return [
									...newFunds,
									{
										...data,
										demembrementDuration: +demembrement.target.value,
										initialDepositAmount: calculateAmount(
											data,
											data.nbShare!,
											data.projectType!,
											+demembrement.target.value,
										),
									},
								].sort((a, b) => a.id - b.id);
							});
						}}
					>
						{Object.keys(data.demembrementData).map((f) => (
							<option value={f} key={f}>
								{f} ans ({(100 - data.demembrementData![f] * 100).toFixed(1)}%)
							</option>
						))}
					</Select>
				</Box>
			)}
		</HStack>
		{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
		<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
	</VStack>
);
