import { FC, useMemo, useState } from 'react';
import { chakra, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { isNotNone } from 'utils/functions';

import FilePicker from './FilePicker';
import CustomModal from './Modal';

export type DocumentToUpload = Record<string, { label: string; content: File[] | null }>;

type RequiredDocsUploadProps = {
	files: DocumentToUpload;
	addFiles: (key: string, value: File[]) => void;
};

const RequiredDocsUpload: FC<RequiredDocsUploadProps> = ({ files, addFiles }) => {
	const { onToggle: onToggleFile, isOpen: isOpenFile } = useDisclosure();

	const [viewFile, setViewFile] = useState<string | null>(null);

	const viewFileContent = useMemo(
		() => (isNotNone(viewFile) && isNotNone(files) ? files[viewFile].content : null),
		[files, viewFile],
	);

	return (
		<VStack align="start" mt="16px">
			{Object.entries(files).map(([key, file]) => (
				<VStack key={key} align="start">
					<VStack align="start">
						<Text
							_hover={isNotNone(file.content) ? { cursor: 'pointer', textDecoration: 'underline' } : undefined}
							onClick={() => {
								onToggleFile();
								setViewFile(key);
							}}
						>
							{file.label}:
						</Text>
						<FilePicker
							multiple={false}
							onDrop={(acceptedFiles) => addFiles(key, acceptedFiles)}
							files={file.content}
							shouldShowSelectedFiles={!!file.content}
							shouldShowSelectButton
						/>
					</VStack>
				</VStack>
			))}

			{isNotNone(viewFileContent) && (
				<CustomModal isOpen={isOpenFile} onClose={onToggleFile}>
					{viewFileContent[0]?.type.includes('image') ? (
						<chakra.img src={window.URL.createObjectURL(viewFileContent[0])} m="auto" />
					) : (
						<chakra.iframe src={window.URL.createObjectURL(viewFileContent[0])} w="100%" h="100%" />
					)}
				</CustomModal>
			)}
		</VStack>
	);
};

export default RequiredDocsUpload;
