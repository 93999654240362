import { dashboardApi } from 'store/api';

import { OpsInvestDeal } from '../deal';

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllInvestSubscriptions: builder.query<OpsInvestDeal[], void>({
			query: () => ({
				url: '/bo/rattachement/invest/all',
				method: 'GET',
			}),
			providesTags: ['OPS_SUBSCRIPTIONS'],
		}),
		updateInvestSubscriptionRattachement: builder.mutation<
			void,
			{ id: string; status: string; externalSubscriptionId: string; ignoreEventsContractOpening: boolean }
		>({
			query: ({ id, status, externalSubscriptionId, ignoreEventsContractOpening }) => ({
				url: `/bo/rattachement/subscription/${id}`,
				method: 'PUT',
				body: { status, externalSubscriptionId, ignoreEventsContractOpening },
			}),
			invalidatesTags: ['OPS_SUBSCRIPTIONS'],
		}),
	}),
});

export const { useGetAllInvestSubscriptionsQuery, useUpdateInvestSubscriptionRattachementMutation } = endpoints;
