import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, VStack } from '@chakra-ui/react';
import { format } from 'd3-format';

import NumberInputWithStepper from 'components/NumberInput';
import { ScpiDirectSimulationForm } from 'pages/cpm/product-propale/scpiDirect/forms/formTypes';

type InvestInformationsProps = {
	formMethods: UseFormReturn<ScpiDirectSimulationForm>;
};

const InvestInformations: FC<InvestInformationsProps> = ({ formMethods }) => {
	const { control } = formMethods;

	return (
		<VStack align="start" spacing="30px" w="100%">
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Montant de l'investissement</Text>
				<Controller
					name="amount"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={1000}
							step={100}
							value={`${format(',.3~f')(field.value)} €`}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</VStack>
			<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
				<VStack w="100%" align="start">
					<Text variant="Text-S-Regular">Durée de l'investissement</Text>
					<Text fontSize="sm" color="grey.500">
						ans
					</Text>
				</VStack>
				<VStack w="100%" align="start">
					<Controller
						name="time"
						control={control}
						rules={{ required: true }}
						render={({ field }) => (
							<Slider
								value={field.value}
								min={4}
								max={20}
								step={1}
								flex={1}
								colorScheme="blue"
								onChange={(val) => field.onChange(val)}
							>
								<SliderTrack>
									<SliderFilledTrack />
								</SliderTrack>
								<SliderThumb />
							</Slider>
						)}
					/>
					<Text fontSize="sm" color="grey.500">
						{formMethods.watch('time')} /20
					</Text>
				</VStack>
			</HStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Revenus annuels nets imposables du foyer fiscal</Text>
				<Controller
					name="annualRevenue"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={100}
							value={`${format(',.3~f')(field.value)} €`}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</VStack>
		</VStack>
	);
};

export default InvestInformations;
