import { SubscriptionStatus } from 'types/global.type';

import { BeneficiaryType } from './invest-deal.type';

export enum AvLuxOperationType {
	DIRECT_DEBIT = 'DIRECT_DEBIT',
	BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum AvLuxProvider {
	ONELIFE = 'ONELIFE',
}

export const avLuxProviderLabels: Record<AvLuxProvider, string> = {
	[AvLuxProvider.ONELIFE]: 'OneLife',
};

export enum AvLuxFundOriginType {
	EPARGNE_CONSTITUEE = 'EPARGNE_CONSTITUEE',
	REVENUS_PROFESIONNELS = 'REVENUS_PROFESIONNELS',
	VENTE_IMMOBILIER = 'VENTE_IMMOBILIER',
	SUCCESSION_DONATION = 'SUCCESSION_DONATION',
	CESSION_ACTIFS = 'CESSION_ACTIFS',
	BIEN_MOBILIER = 'BIEN_MOBILIER',
}

export enum AvLuxJustificatifs {
	LAST_RELEVE_COMPTE = 'lastReleveCompte',
	LAST_AVIS_IMPOSITION = 'lastAvisImposition',
	NOTE_CONTEXETUELLE = 'noteContextuelle',
	ATTESTATION_NOTAIRE = 'attestationNotaire',
	ATTESTATION_RACHAT = 'attestationRachat',
	SPECIFIQUE_RELVER_COMPTE = 'specifiqueReleveDeCompte',
}

export type AvLuxStatus =
	| SubscriptionStatus.REQUESTED
	| SubscriptionStatus.PENDING
	| SubscriptionStatus.KYC_VALIDATION
	| SubscriptionStatus.CONTRACT_SENT_TO_CLIENT
	| SubscriptionStatus.SIGNED
	| SubscriptionStatus.PARTNER_VALIDATION
	| SubscriptionStatus.PAYMENT
	| SubscriptionStatus.REGISTERED
	| SubscriptionStatus.COMPLETED
	| SubscriptionStatus.TERMINATED;

export type AvLuxUserAsset = {
	id: string;
	isin: string;
	name: string;
	montantEuro: number;
	percentage: number;
	nbShares: number;
	pricePerShare: number;
	avLuxContractId: string;
};

export type AvLuxContract = {
	id: string;
	createdAt: string;
	userId: string;
	bankInformationId?: string;

	initialDepositAmount: string;
	initialDepositDate?: string;
	valorisation?: string;
	risk?: number;

	hubspotId?: string;
	opsPropertiesId?: string;
	contractIdOneLife?: string;

	beneficiary?: BeneficiaryType;
	fundOriginType?: AvLuxFundOriginType;
	operationType?: AvLuxOperationType;
	provider?: AvLuxProvider;

	status: AvLuxStatus;
	requestedAt?: string;
	pendingAt?: string;
	kycValidationAt?: string;
	contractSentToClientAt?: string;
	signedAt?: string;
	partnerValidationAt?: string;
	paymentAt?: string;
	registeredAt?: string;
	completedAt?: string;
	terminatedAt?: string;
};
