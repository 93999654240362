import { LabArbitrageTableResponse } from 'services/qis';
import { BankInformation } from 'types/bank-information.type';

import { SubscriptionStatus } from './global.type';
import { InvestmentPreferences } from './investmentPreferences.type';
import { KYC } from './kyc.type';
import { User } from './user.type';

export enum EnvelopeType {
	PER = 'PER',
	AV = 'AV',
	AV_HYBRIDE = 'AV_HYBRIDE',
}

export enum InvestEnvelopePartnerCode {
	APICIL_PER_GENERIQUE = 'P2',
	APICIL_AV_GENERIQUE = 'IL',
	APICIL_AV_DEDIE = 'RAMV',
	APICIL_PER_DEDIE = 'RAMP',
	APICIL_AV_CAPI = 'SP08',
	APICIL_AV_HYBRIDE = 'SV09',
	GENERALI_AV = 'param.48504851',
	LMEP_AV_LUX = 'GA10149', // todo LMEP @LorenzoRosmarino
}

export interface Envelope {
	id: string;
	type: EnvelopeType;
	name: string;
	partnerCode: InvestEnvelopePartnerCode;
	provider: InsuranceProvider;
}

export enum PeriodicityType {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
	SEMI_ANNUALLY = 'SEMI_ANNUALLY',
	ANNUALLY = 'ANNUALLY',
}

export enum BeneficiaryType {
	SPOUSE = 'SPOUSE',
	CHILDREN = 'CHILDREN',
	HEIRS = 'HEIRS',
	WRITTEN = 'WRITTEN',
}

export enum FundOriginType {
	PROFESSIONAL_INCOME = 'PROFESSIONAL_INCOME',
	REAL_ESTATE = 'REAL_ESTATE',
	SUCCESSION_DONATION = 'SUCCESSION_DONATION',
	ASSET_SELLOUT = 'ASSET_SELLOUT',
	GOODS_SELLOUT = 'GOODS_SELLOUT',
}

export enum InvestRepresentativeType {
	INDIVIDUAL = 'INDIVIDUAL',
	PROFESSIONAL = 'PROFESSIONAL',
}

export type BankInformations = {
	id: string;
	IBAN: string;
	BIC: string;
	holder: string;
};

export enum InsuranceProvider {
	APICIL = 'APICIL',
	GENERALI = 'GENERALI',
}

export enum InvestProjectType {
	RETIREMENT = 'RETIREMENT',
	OTHER = 'OTHER',
}

export type InvestDealStatus =
	| SubscriptionStatus.REQUESTED
	| SubscriptionStatus.SIGNED
	| SubscriptionStatus.PARTNER_VALIDATION
	| SubscriptionStatus.PAYMENT
	| SubscriptionStatus.COMPLETED
	| SubscriptionStatus.TERMINATED;

export type ContractHolding = {
	ISIN: string;
	montantEuro: string;
	nbShares: number;
};

export type InvestProgrammedVersement = {
	id: string;
	contractId: string;
	amount: string;
	startDate: string;
	period: string;
	day: number | null;

	fundOriginType: FundOriginType | null;
	advancedFundOriginTypes: Record<FundOriginType, number> | null;

	// currentAllocation: object | null
	// targetAllocation: object | null
};

export enum VersementRequestType {
	PROGRAMME = 'PROGRAMME',
	COMPLEMENTAIRE = 'COMPLEMENTAIRE',
}

export interface InvestDeal {
	id: string;
	userId: string;
	createdAt: string;
	updatedAt: string;

	status: InvestDealStatus;
	initialDepositAmount: string;
	timeHorizon: string | null;
	projectType: InvestProjectType | null;
	extraData: Partial<{ workedYears: number; birthYear: number }> | null;
	representativeType: InvestRepresentativeType;
	envelopeId: string;
	bankInformationId: string | null;
	ignoreEventsContractOpening: boolean | null;
	signedAt: string | null;
	partnerValidationAt: string | null;
	paymentAt: string | null;
	completedAt: string | null;
	terminatedAt: string | null;
	externalProviderId: string | null;
	externalSubscriptionProviderId: string | null;
	hubspotId: string | null;
	fundOriginType: FundOriginType | null;
	advancedFundOriginTypes: Record<FundOriginType, number> | null;
	beneficiary: BeneficiaryType | null;
	freeBeneficiaryClause: string | null;
	opsPropertiesId: string | null;
	dematerializedCommunication: boolean | null;
	isInResilationPeriod: boolean | null;
	amount: string | null;
	unvaluedBalance: string;
	totalVersement: string | null;
	synchronisedAt: string | null;
	effectDate: string | null;
	lastRebalancingAt: string | null;
	rebalancingData: LabArbitrageTableResponse | null;
}

export type PopulatedInvestDeal = InvestDeal & {
	programmedVersement: InvestProgrammedVersement | null;
	envelope: Envelope;
	investmentPreferences: InvestmentPreferences | null;
	holdings: ContractHolding[];
	bankInformation: BankInformation | null;
};

export type ContractRebalancing = InvestDeal & {
	rebalancingData: LabArbitrageTableResponse;
	investmentPreferences: InvestmentPreferences;
	user: User & {
		kyc: KYC;
	};
};
