import { FC, useState } from 'react';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';

import BasicCard from 'components/BasicCard';
import useThemedToast from 'hooks/useThemedToast';
import { useLazyGetArtAumQuery } from 'services/ops/aum';
import { displayMoney } from 'utils/functions';

const ArtAumCard: FC = () => {
	const toast = useThemedToast();

	const [computeArtAum, { data, isFetching: isArtFetching }] = useLazyGetArtAumQuery();

	const [art, setArt] = useState('');

	return (
		<BasicCard
			title="Collecte ART"
			description="Obtenir les informations sur un deal ART"
			buttonText="Obtenir"
			isLoading={isArtFetching}
			onClick={() => {
				computeArtAum({ art })
					.unwrap()
					.catch((error) => {
						toast({ status: 'error', title: 'Erreur', description: error.data.message });
					});
			}}
			body={
				<VStack align="start">
					<Input placeholder="Id du fond" value={art} onChange={(e) => setArt(e.target.value)} />
					{data?.totalAum && (
						<HStack>
							<Text variant="Text-M-Bold">Collecte totale :</Text>
							<Text>{displayMoney(data.totalAum.toString())}</Text>
						</HStack>
					)}
				</VStack>
			}
		/>
	);
};

export default ArtAumCard;
