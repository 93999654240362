import { FC } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import chroma from 'chroma-js';
import { format } from 'd3-format';

const SIZES = {
	margin: {
		sm: { top: 10, right: 200, bottom: 10, left: 0 },
		md: { top: 40, right: 200, bottom: 40, left: 0 },
		lg: { top: 120, right: 270, bottom: 80, left: 10 },
	},
};

type GraphPieProps = {
	title?: string;
	data: { id: string; label: string; value: number }[];
	size?: 'sm' | 'md' | 'lg';
};

const baseColors = ['#856432', '#B4843B', '#EFC27E', '#F2D6AC', '#F5E5CE'];

const GraphPie: FC<GraphPieProps> = ({ title, data, size = 'lg' }) => {
	const sortedData = data.sort((a, b) => b.value - a.value);
	const gradientColors = chroma.scale(baseColors).mode('lab').colors(sortedData.length);

	return (
		<VStack spacing={4} align="center" w="100%">
			{title && <Text fontSize="xl">{title}</Text>}
			<Box h="450px" w="97%" bg="white">
				<ResponsivePie
					data={sortedData}
					sortByValue
					margin={SIZES.margin[size]}
					innerRadius={0.45}
					enableArcLabels={false}
					legends={[
						{
							anchor: 'top-right',
							direction: 'column',
							itemWidth: 20,
							itemHeight: 20,
							itemsSpacing: 5,
							symbolSize: 15,
							itemDirection: 'left-to-right',
							translateX: 0,
						},
					]}
					colors={gradientColors}
					tooltip={({ datum }) => (
						<VStack bg="white" p={4} borderRadius={4} boxShadow="md">
							<Text>{datum.label}</Text>
							<Text>
								{format(',.3~f')(datum.value)} (
								{format('.0%')(datum.value / sortedData.map((d) => d.value).reduce((a, b) => a + b))})
							</Text>
						</VStack>
					)}
					theme={{
						labels: {
							text: {
								fontSize: 14,
							},
						},
					}}
					arcLinkLabel={(e) => `${format('.1%')(e.value)}`}
					arcLinkLabelsOffset={10}
					arcLinkLabelsTextOffset={1}
					arcLinkLabelsThickness={3}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLinkLabelsDiagonalLength={25}
					arcLinkLabelsStraightLength={5}
					startAngle={-80}
					arcLinkLabelsSkipAngle={0}
				/>
			</Box>
		</VStack>
	);
};

export default GraphPie;
