import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	Skeleton,
	Switch,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import { CommentsSection, useOpsComments } from 'components/Comment';
import HistorySection from 'components/History';
import DictDisplayTable from 'components/tables/DictDisplayTable';
import { ChangeTransferOverdueDate } from 'features/ChangeOverdueDate';
import MailFollowUpSection from 'features/mailing-framework/MailFollowUpSection';
import useAssignedOps, { NO_ASSIGNED_OPS, SelectAssignedOps } from 'hooks/useAssignedOps';
import useThemedToast from 'hooks/useThemedToast';
import { useGetClientKYCQuery } from 'services/client';
import { useGetStatusesQuery } from 'services/ops/status';
import { useDeleteTransferMutation, useGetTransferByIdQuery, useUpdateTransferMutation } from 'services/ops/transfer';
import { SubscriptionStatus } from 'types/global.type';
import { TransferStatus } from 'types/perTransfer.type';
import { fieldTranslation } from 'utils/fieldTranslation';
import { isNone, isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

export const TransferDetails = () => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

	const { data: transfer, isFetching: isTransferFetching } = useGetTransferByIdQuery(
		{ id: id ?? '' },
		{ skip: !isNotNone(id) },
	);
	const { data: kyc } = useGetClientKYCQuery({ email: transfer?.user.email ?? '' }, { skip: isNone(transfer) });
	const { data: statuses } = useGetStatusesQuery();
	const [updateTransfer, { isLoading: isUpdateLoading }] = useUpdateTransferMutation();

	const comments = useOpsComments();
	const { assignedOps, onChangeAssignedOps } = useAssignedOps(NO_ASSIGNED_OPS);
	const opsProperties = useMemo(() => transfer?.opsProperties, [transfer?.opsProperties]);

	const [status, setStatus] = useState<TransferStatus | undefined>(undefined);
	const [isElite, setIsElite] = useState<boolean>(false);
	const [validationInput, setValidationInput] = useState<string>('');

	const dirtyFields = useMemo(() => {
		const isStatusDirty = status !== transfer?.status;
		const isAssignedOpsDirty = opsProperties?.assignedOpsEmail !== assignedOps && assignedOps !== NO_ASSIGNED_OPS;
		const isEliteDirty = isElite !== transfer?.isElite;
		return { isStatusDirty, isAssignedOpsDirty, isEliteDirty };
	}, [assignedOps, isElite, opsProperties?.assignedOpsEmail, status, transfer?.isElite, transfer?.status]);

	const handleUpdate = useCallback(() => {
		updateTransfer({
			id: transfer!.id,
			status: dirtyFields.isStatusDirty ? status : undefined,
			isElite: dirtyFields.isEliteDirty ? isElite : undefined,
			properties: {
				id: transfer!.opsPropertiesId,
				assignedOpsEmail: dirtyFields.isAssignedOpsDirty ? assignedOps : undefined,
			},
		})
			.unwrap()
			.then(() => {
				setStatus(undefined);
				toast({ status: 'success', title: 'Demande de transfert mise à jour avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		// eslint-disable-next-line prettier/prettier
	}, [assignedOps, dirtyFields.isAssignedOpsDirty, dirtyFields.isEliteDirty, dirtyFields.isStatusDirty, isElite, status, toast, transfer, updateTransfer]);

	const [deleteTransfer, { isLoading: isDeleteLoading }] = useDeleteTransferMutation();

	const handleDelete = useCallback(() => {
		deleteTransfer({ id: transfer!.id })
			.unwrap()
			.then(() => {
				setValidationInput('');
				onDeleteClose();
				navigate('..');
				toast({ status: 'success', title: 'Demande de transfert supprimée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	}, [deleteTransfer, navigate, onDeleteClose, toast, transfer]);

	const handleSendComment = useCallback(() => {
		// If the transfer has no opsPropertiesId, we need to generate it before sending the comment
		if (!transfer?.opsPropertiesId) {
			updateTransfer({
				id: transfer!.id,
				properties: { id: transfer!.opsPropertiesId, comment: comments.comment },
			});
			comments.onChangeComment('');
		} else comments.onCreateComment(transfer.opsPropertiesId!);
	}, [comments, transfer, updateTransfer]);

	useEffect(() => {
		if (transfer?.status) setStatus(transfer.status);
		if (transfer?.isElite) setIsElite(transfer.isElite);
		if (isNotNone(opsProperties?.assignedOpsEmail)) onChangeAssignedOps(opsProperties?.assignedOpsEmail);
	}, [onChangeAssignedOps, opsProperties?.assignedOpsEmail, transfer?.isElite, transfer?.status]);

	if (isTransferFetching) return <Skeleton h="100%" w="100%" />;
	if (isNone(transfer))
		return (
			<Center>
				<Heading size="md">Transfer non trouvé</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Transfers</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{transfer.user.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc ? `${kyc?.kyc?.firstName} ${kyc?.kyc?.lastName}` : 'Nom et prénom manquants'}
				</Heading>

				<HStack align="start">
					<Button
						colorScheme="blue"
						isDisabled={Object.values(dirtyFields).every((i) => !i)}
						onClick={handleUpdate}
						isLoading={isUpdateLoading}
					>
						Enregistrer les modifications
					</Button>
					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => navigate(`/ops/super/client/${transfer.user.id}`)}>Page client</MenuItem>
							<PermissionDisplayGuard permission={BoPermission.BLOCKING_INSTANCE_DELETE}>
								<MenuItem color="red.600" onClick={onDeleteOpen}>
									Supprimer le transfert
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<HStack w="100%" align="start" spacing="12px" justify="space-between">
				<VStack spacing="12px" align="start" flex={3}>
					<Cardlayout title="Récapitulatif" defaultIsOpen={false}>
						<DictDisplayTable dict={transfer} fieldToRemove={['userId']} translation={fieldTranslation} />
					</Cardlayout>

					<MailFollowUpSection conversationId={opsProperties?.mailingFrameworkConversation?.id} />

					<CommentsSection
						comment={comments.comment}
						commentList={opsProperties?.comments}
						onCreateComment={handleSendComment}
						onChangeComment={comments.onChangeComment}
						onDeleteComment={comments.onDeleteComment}
						isLoading={isUpdateLoading}
					/>

					<HistorySection opsProperties={opsProperties} />
				</VStack>

				<VStack spacing="12px" align="start" flex={1}>
					<Cardlayout title="Mettre à jour">
						<VStack align="start" spacing="16px" w="100%">
							<SelectAssignedOps
								isLoading={isUpdateLoading}
								allowNone
								assignedOps={assignedOps}
								onChangeAssignedOps={onChangeAssignedOps}
							/>
							<VStack align="start" w="100%">
								<Text>Statut</Text>
								<Select value={status} onChange={(e) => setStatus(e.target.value as TransferStatus)}>
									<option value={SubscriptionStatus.REQUESTED}>REQUESTED</option>
									<option value={SubscriptionStatus.SIGNED}>SIGNED</option>
									<option value={SubscriptionStatus.PARTNER_VALIDATION}>PARTNER_VALIDATION</option>
									<option value={SubscriptionStatus.PARTNER_TREATMENT}>PARTNER_TREATMENT</option>
									<option value={SubscriptionStatus.PAYMENT}>PAYMENT</option>
									<option value={SubscriptionStatus.COMPLETED}>COMPLETED</option>
								</Select>
							</VStack>
							<VStack align="start" w="100%">
								<Text>Elite</Text>
								<Switch
									isChecked={isElite}
									onChange={(e) => setIsElite(e.target.checked)}
									isDisabled={isUpdateLoading}
								/>
							</VStack>
						</VStack>
					</Cardlayout>

					{statuses && ![SubscriptionStatus.COMPLETED, SubscriptionStatus.REQUESTED].includes(transfer.status) && (
						<Cardlayout title="Deadline">
							<VStack align="start" spacing="16px" w="100%">
								<ChangeTransferOverdueDate overdueItem={transfer} statuses={statuses} />
							</VStack>
						</Cardlayout>
					)}
				</VStack>
			</HStack>

			<AlertDialog
				isOpen={isDeleteOpen}
				onClose={onDeleteClose}
				header="Supprimer cette demande de transfert"
				body={
					<VStack spacing="16px" align="start">
						<Text>Vous êtes sur le point de supprimer cette demande de transfert.</Text>
						<Text>
							Veuillez confirmer cette opération en écrivant <i>valider la suppression</i>
						</Text>
						<Input
							value={validationInput}
							onChange={(e) => setValidationInput(e.target.value)}
							placeholder="valider la suppression"
						/>
					</VStack>
				}
				footer={
					<HStack w="100%" justify="space-between">
						<Button onClick={onDeleteClose}>Annuler</Button>
						<Box>
							<Button
								colorScheme="red"
								ml={3}
								isLoading={isDeleteLoading}
								isDisabled={validationInput !== 'valider la suppression'}
								onClick={handleDelete}
							>
								Supprimer
							</Button>
						</Box>
					</HStack>
				}
			/>
		</VStack>
	);
};
