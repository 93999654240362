import { dashboardApi } from 'store/api';
import { CryptoBoUserProfile, CryptoSubscription } from 'types/crypto.types';

// CONSTANT
const ONFIDO_WORKFLOW_VIRTUAL_IBAN = '6beab035-8dd6-4c17-8962-9fa64ea2d4ca';
export const OnfidoWorkflowTranslate: Record<string, string> = {
	[ONFIDO_WORKFLOW_VIRTUAL_IBAN]: 'Virtual Iban',
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getCryptoSubscriptions: builder.query<CryptoSubscription[], void>({
			query: () => ({
				url: `/bo/ops/crypto/subscriptions`,
				method: 'GET',
			}),
			providesTags: ['OPS_CRYPTO_SUBSCRIPTIONS'],
		}),
		getCryptoPartnerAdequation: builder.query<CryptoSubscription[], void>({
			query: () => ({
				url: `/bo/ops/crypto/partner-adequation`,
				method: 'GET',
			}),
			providesTags: ['OPS_CRYPTO_SUBSCRIPTIONS'],
		}),

		getCryptoProfileByEmail: builder.query<CryptoBoUserProfile, { email: string }>({
			query: ({ email }) => ({
				url: `/bo/ops/crypto/profile/${email}`,
				method: 'GET',
			}),
			providesTags: ['OPS_CRYPTO_SUBSCRIPTIONS'],
		}),

		resetPartnerAdequation: builder.mutation<void, { email: string }>({
			query: (body) => ({
				url: `/bo/ops/crypto/partner-adequation/reset-flow`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['OPS_CRYPTO_SUBSCRIPTIONS'],
		}),
		unlockPartnerAdequation: builder.mutation<void, { email: string }>({
			query: (body) => ({
				url: `/bo/ops/crypto/partner-adequation/unlock`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['OPS_CRYPTO_SUBSCRIPTIONS'],
		}),
		validateCryptoSubscription: builder.mutation<void, { email: string }>({
			query: (body) => ({
				url: `/bo/ops/crypto/subscription`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['OPS_CRYPTO_SUBSCRIPTIONS'],
		}),
	}),
});

export const {
	// Global Queries
	useGetCryptoSubscriptionsQuery,
	useGetCryptoPartnerAdequationQuery,

	// Get User data
	useGetCryptoProfileByEmailQuery,

	// Subscription flow's mutations
	useUnlockPartnerAdequationMutation,
	useResetPartnerAdequationMutation,
	useValidateCryptoSubscriptionMutation,
} = endpoints;
