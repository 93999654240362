import { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Center, Divider, Heading, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { format } from 'd3-format';

import GraphPie from 'components/graphs/GraphPie';
import ScpiTable from 'components/graphs/ScpiTable';
import TableGraph from 'components/graphs/TableGraph';
import CreditInformations from 'pages/cpm/product-propale/scpiCredit/forms/creditInformations';
import { ScpiCreditSimulationForm } from 'pages/cpm/product-propale/scpiCredit/forms/formTypes';
import PersonalInformation from 'pages/cpm/product-propale/scpiCredit/forms/personalInformation';
import ValorisationTable from 'pages/cpm/product-propale/scpiCredit/valorisationTable';
import {
	useCreateScpiCreditRecommendationMutation,
	useCreateScpiCreditSimulationMutation,
	useGenerateNotionPageMutation,
} from 'services/cpm/scpi-credit';
import { LabSCPIRecommendationResponse, ScpiRecommendationData } from 'services/cpm/scpi-direct';

function computeCumulDividends(table: Record<number, number>) {
	const values = Object.values(table);
	return values.reduce((acc, value) => acc + value, 0);
}

function computeMeanYearlyDividends(table: Record<number, number>) {
	const values = Object.values(table);
	if (values.length === 0) return 0;

	const total = values.reduce((acc, value) => acc + value, 0);
	return total / values.length;
}

function computeMeanDividendsMonth(table: Record<number, number>) {
	const values = Object.values(table);
	if (values.length === 0) return 0;

	const total = values.reduce((acc, value) => acc + value, 0);
	return total / (values.length * 12);
}

const PropaleScpiCredit: FC = () => {
	const [recommendationTable, setRecommendationTable] = useState<LabSCPIRecommendationResponse | null>(null);
	const [mensualite, setMensualite] = useState<number>(0);

	const [generateNotionPage, { isLoading: isLoadingNotionPage }] = useGenerateNotionPageMutation();
	const [createScpiCreditRecommendation, { isLoading: isLoadingRecommendation }] =
		useCreateScpiCreditRecommendationMutation();
	const [createScpiCreditSimulation, { data: simulationResult, isLoading: isLoadingSimulation }] =
		useCreateScpiCreditSimulationMutation();

	const totalInvestment = useMemo(() => {
		if (!recommendationTable) return 0;
		return Object.values(recommendationTable.allocation).reduce(
			(acc, scpi) => acc + scpi.montant_nu + scpi.montant_plein,
			0,
		);
	}, [recommendationTable]);

	const formMethods = useForm<ScpiCreditSimulationForm>({
		mode: 'onChange',
		defaultValues: {
			firstName: '',
			lastName: '',

			creditDuration: 4,
			creditAmount: 50000,
			maxMonthlySavings: 0,
			annualNetIncome: 50000,
			interestRate: 4.99,

			creditUniverse: 'cib cfcal',
			isr: false,
			personalContribution: 0,
			moralPerson: false,
			familySituation: 'SINGLE',
			nbChildren: 0,
			abroadPercentage: 40,
			maxScpiByPartner: 1,
		},
	});

	const handleReco = formMethods.handleSubmit(async (data) => {
		const response = await createScpiCreditRecommendation({
			investment_horizon: data.creditDuration,
			own_initial_investment: Number(data.personalContribution),
			credit: Number(data.creditAmount),
			credit_horizon: data.creditDuration,
			credit_rate: data.interestRate / 100,
			credit_actor: data.creditUniverse,
			personne_morale: data.moralPerson,
			family_situation: data.familySituation.toLocaleLowerCase(),
			isISR: data.isr,
			reco_filter: true,
			retool_reco_filter: true,
			annual_net_revenue: Number(data.annualNetIncome),
			number_children: data.nbChildren,
			epargne_mensuel_max: Number(data.maxMonthlySavings),
			etranger_percentage_minimum: Number(data.abroadPercentage) / 100,
			scpi_maximal_number_per_partner: data.maxScpiByPartner,
			has_filled_tax_form: false,
			has_filled_tax_shares_form: false,
		}).unwrap();
		const newRecommendationTable = {
			allocation: Object.fromEntries(
				Object.entries(response.allocation || {}).map(([scpiName, scpiData]) => [
					scpiName,
					{
						parts_plein: (scpiData as ScpiRecommendationData).parts_plein || 0,
						parts_nue: (scpiData as ScpiRecommendationData).parts_nue || 0,
						montant_plein: (scpiData as ScpiRecommendationData).montant_plein || 0,
						montant_nu: (scpiData as ScpiRecommendationData).montant_nu || 0,
						id_airtable: (scpiData as ScpiRecommendationData).id_airtable || '',
						decote_period: (scpiData as ScpiRecommendationData).decote_period || 0,
					},
				]),
			),
			remise: response.remise,
			cost: response.cost,
		};
		setRecommendationTable(newRecommendationTable);
	});

	const handleSimulation = formMethods.handleSubmit(async (data) => {
		if (!recommendationTable) return;
		const portfolioAllocation = Object.entries(recommendationTable.allocation || {}).map(([, value]) => ({
			id: value.id_airtable,
			number_of_shares: value.parts_nue + value.parts_plein,
			montant_investi: value.montant_nu + value.montant_plein,
			decote_period: value.decote_period,
		}));

		const response = await createScpiCreditSimulation({
			investment_horizon: data.creditDuration,
			annual_net_revenue: Number(data.annualNetIncome),
			number_children: data.nbChildren,
			family_situation: data.familySituation.toLocaleLowerCase(),
			portfolio_allocation: portfolioAllocation,
			own_initial_investment: Number(data.personalContribution),
			retool_reco_filter: true,
			has_filled_tax_form: false,
			has_filled_tax_shares_form: false,
			credit: totalInvestment,
			credit_actor: data.creditUniverse,
			credit_horizon: data.creditDuration,
			credit_rate: data.interestRate / 100,
			personne_morale: data.moralPerson,
		}).unwrap();

		const len = Object.keys(response.valo['valo net']).length - 1;
		const interetAnnuelPayeLast = response.table.interet_annuel_paye[len];
		const principalAnnuelPayeLast = response.table.principal_annuel_paye[len];
		setMensualite((interetAnnuelPayeLast + principalAnnuelPayeLast) / 12);
	});

	const handleCreatePage = formMethods.handleSubmit(async (data) => {
		if (!simulationResult) return;
		generateNotionPage({
			first_name: data.firstName,
			last_name: data.lastName,
			personal_contribution: data.personalContribution.toString(),
			credit_amount: format(',.3~f')(simulationResult.stats['Montant investi']),
			credit_horizon: format(',.2~f')(data.creditDuration),
			tri: format(',.2~f')(100 * simulationResult.stats['IRR hors remise et cout']),
			diversification: 'Pas de préférence',

			performance: format(',.2~f')(
				100 *
					(simulationResult.stats['taux de distribution moyen estimé'] +
						simulationResult.stats['Taux de revalorisation annuel']),
			),
			distribution_rate: format(',.2~f')(100 * simulationResult.stats['taux de distribution moyen estimé']),
			annual_revaluation: format(',.2~f')(100 * simulationResult.stats['Taux de revalorisation annuel']),
			abroad_percentage: format(',.2~f')(100 * simulationResult.repartitons.geoEtranger),
			interest_rate: format(',.2~f')(data.interestRate),
			entry_fees: format(',.2~f')(simulationResult.stats['Frais d’entrées']),
			cumul_dividends: format(',.2~f')(computeCumulDividends(simulationResult.table.dividendes_brutes)),
			mean_div_year: format(',.2~f')(computeMeanYearlyDividends(simulationResult.table.dividendes_brutes)),
			mean_div_month: format(',.2~f')(computeMeanDividendsMonth(simulationResult.table.dividendes_brutes)),
			nb_scpi: Object.keys(recommendationTable?.allocation || {}).length.toString(),
			mensualite: format(',.4~f')(mensualite),
			epargne: format(',.2~f')(simulationResult.stats['epargne effort moyen']),

			scpi_investments: Object.entries(recommendationTable?.allocation || {}).map(([scpi, scpi_data]) => ({
				scpi,
				amount: Number(scpi_data.montant_nu) + Number(scpi_data.montant_plein),
			})),

			repartition: Object.entries(simulationResult.repartitons).map(([key, value]) => ({
				montant: Number(value),
				label: key,
			})),

			simulation_result_table: simulationResult.table,
		});
	});

	return (
		<Center p="24px" w="100%" pb="80px">
			<VStack spacing="32px" w="100%">
				<VStack w="100%" spacing="32px" maxW="1080px">
					<Heading size="md">Simulation - SCPI Crédit</Heading>
					<Divider />
					<HStack spacing="64px" w="100%">
						<CreditInformations formMethods={formMethods} />
						<PersonalInformation formMethods={formMethods} />
					</HStack>
					<Button
						w="50%"
						isDisabled={!formMethods.formState.isValid}
						onClick={handleReco}
						colorScheme="yellow"
						isLoading={isLoadingRecommendation}
					>
						Generer la recommendation Ramify
					</Button>
					{recommendationTable && (
						<VStack spacing="32px" w="100%" maxW="1080px">
							<Divider />
							<HStack w="100%" justifyContent="center">
								<ScpiTable recommendationTable={recommendationTable} setRecommendationTable={setRecommendationTable} />
								<VStack w="100%">
									<Box w="80%" justifyContent="space-between" p="16px" bg="gray.100">
										<HStack w="100%" justifyContent="space-between">
											<Text fontSize="md">Remise:</Text>
											<Text fontSize="md">{format(',.3~f')(recommendationTable.remise ?? 0)} €</Text>
										</HStack>
										<HStack w="100%" justifyContent="space-between">
											<Text fontSize="md">Cost:</Text>
											<Text fontSize="md">{format(',.3~f')(recommendationTable.cost ?? 0)} €</Text>
										</HStack>
									</Box>
								</VStack>
							</HStack>
							<Heading size="md">Montant total investi: {format(',.3~f')(totalInvestment)} €</Heading>
							<Button w="50%" onClick={handleSimulation} colorScheme="yellow" isLoading={isLoadingSimulation}>
								Faire une simulation
							</Button>
						</VStack>
					)}
				</VStack>
				{simulationResult && (
					<VStack w="100%" spacing="32px">
						<Divider />
						<HStack w="100%" justifyContent="space-around" alignItems="self-start">
							<VStack w="50%" justifyContent={{ base: 'center', md: 'space-between' }}>
								<GraphPie
									title="Répartition sectorielle"
									data={Object.entries(simulationResult.repartitons)
										.filter(([key]) => !key.includes('geo'))
										.map(([key, value]) => ({
											id: key,
											label: key.replace('secteur', ''),
											value,
										}))}
								/>
								<GraphPie
									title="Répartition géographique"
									data={Object.entries(simulationResult.repartitons)
										.filter(([key]) => key.includes('geo'))
										.map(([key, value]) => ({
											id: key,
											label: key.replace('geo', ''),
											value,
										}))}
								/>
							</VStack>
							<TableGraph
								titleKey="Chiffres clés"
								titleValue="Valeur"
								data={[
									{ id: 'TRI', value: format(',.3~f')(100 * simulationResult.stats['IRR hors remise et cout']) },
									{
										id: 'TRI avec remise et coûts',
										value: format(',.3~f')(100 * simulationResult.stats['IRR avec remise et cout']),
									},
									{ id: 'cout du crédit', value: format(',.3~f')(simulationResult.stats['cout du crédit']) },
									{ id: 'Remise', value: format(',.3~f')(simulationResult.stats.remise) },
									{
										id: 'TD brute moyen',
										value: format(',.3~f')(100 * simulationResult.stats['taux de distribution moyen estimé']),
									},
									{
										id: 'Revalorisation moyenne',
										value: format(',.3~f')(100 * simulationResult.stats['Taux de revalorisation annuel']),
									},
									{ id: 'Frais entrées globales', value: format(',.3~f')(simulationResult.stats['Frais d’entrées']) },
									{
										id: 'Évolution de la valeur des parts en %',
										value: format(',.3~f')(simulationResult.stats['Évolution de la valeur des parts en %']),
									},
									{
										id: "Effort d'épargne moyen",
										value: format(',.3~f')(simulationResult.stats['epargne effort moyen']),
									},
									{ id: "Effort d'épargne max", value: format(',.3~f')(simulationResult.stats['epargne effort max']) },
									{
										id: 'Gain en capital estimé',
										value: format(',.3~f')(simulationResult.stats['Gain de capital estimé']),
									},
									{ id: 'Mensualité', value: format(',.3~f')(mensualite) },
								]}
							/>
						</HStack>
						<ValorisationTable data={simulationResult.table} />
						<VStack w="100%">
							<HStack w="100%" justifyContent="center">
								<Controller
									name="lastName"
									control={formMethods.control}
									render={({ field }) => <Input {...field} placeholder="Nom" bg="white" w="30%" />}
								/>
								<Controller
									name="firstName"
									control={formMethods.control}
									render={({ field }) => <Input {...field} placeholder="Prénom" bg="white" w="30%" />}
								/>
							</HStack>
							<Button colorScheme="blue" w="50%" onClick={handleCreatePage} isLoading={isLoadingNotionPage}>
								Générer la page Notion
							</Button>
						</VStack>
					</VStack>
				)}
			</VStack>
		</Center>
	);
};

export default PropaleScpiCredit;
