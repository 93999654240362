import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	FormLabel,
	HStack,
	Input,
	Table,
	TableContainer,
	Tbody,
	Td,
	Textarea,
	Tr,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import DatePicker from 'components/DatePicker';
import RequiredDocsUpload, { DocumentToUpload } from 'components/RequiredDocsUpload';
import useDealStatus from 'hooks/useDealStatus';
import useThemedToast from 'hooks/useThemedToast';
import { AllDeal, useUpdateDealMutation, useUploadShareAttestationMutation } from 'services/deal';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { isNone, isNotNone } from 'utils/functions';

import DealBoInfo from './DealBoInfo';

import './markdown.css';

const defaultFiles: DocumentToUpload = {
	sharesAttestation: { content: null, label: 'Attestation des parts' },
};

const NextStatusModal = ({ isOpen, onToggle, deal }: { isOpen: boolean; onToggle: () => void; deal: AllDeal }) => {
	const toast = useThemedToast();

	const { nextStatus } = useDealStatus(deal);

	const [updateStatus, { isLoading }] = useUpdateDealMutation();
	const [uploadShareAttestation, { isLoading: isLoadingUploadAirtableDocs }] = useUploadShareAttestationMutation();

	const [files, setFiles] = useState<DocumentToUpload>(defaultFiles);
	const [nbShares, setNbShares] = useState('');
	const [typePart, setTypePart] = useState('');
	const [comment, setComment] = useState('');
	const [effectiveDate, setEffectiveDate] = useState(new Date());

	const needsDoc = useMemo(
		() =>
			deal.status === SubscriptionStatus.REGISTERED &&
			(deal.productType === ProductType.SCPI ||
				deal.productType === ProductType.PE ||
				deal.productType === ProductType.CROWDFUNDING ||
				deal.productType === ProductType.ART ||
				deal.productType === ProductType.GIRARDIN),
		[deal.productType, deal.status],
	);

	const registeredStatusInfoFilled = useMemo(() => {
		// attestationDesParts is required for all products that reach the REGISTERED status
		if (!Object.values(files).every((f) => isNotNone(f.content))) return false;
		if (deal.productType === ProductType.GIRARDIN) return true;
		if (deal.productType === ProductType.CROWDFUNDING) return true;
		if (deal.productType === ProductType.ART) return true;
		if (deal.productType === ProductType.PE) return isNotNone(typePart);
		if (deal.productType === ProductType.SCPI) return isNotNone(nbShares) && isNotNone(effectiveDate);
	}, [deal.productType, effectiveDate, files, nbShares, typePart]);

	const handleClose = useCallback(() => {
		onToggle();
		setFiles(defaultFiles);
		setNbShares('');
		setTypePart('');
	}, [onToggle]);

	const handleUpdateStatus = useCallback(async () => {
		if (isNone(nextStatus)) return;

		try {
			// upload shares docs and data first if needed, dopending on the product type
			if (needsDoc) {
				const payload = new FormData();

				payload.append('dealId', deal.id);
				payload.append('productType', deal.productType);
				if (nbShares) payload.append('nbShare', nbShares);
				if (typePart) payload.append('partType', typePart);
				if (deal.productType === ProductType.SCPI && effectiveDate)
					payload.append('effectiveDate', effectiveDate.toISOString());
				Object.entries(files).forEach(([key, value]) => {
					if (isNotNone(value.content)) payload.append(key, value.content[0]);
				});

				await uploadShareAttestation(payload).unwrap();
			}

			await updateStatus({
				id: deal.id,
				productType: deal.productType,
				status: nextStatus,
				properties: { id: deal.opsPropertiesId ?? undefined, comment: comment.length > 0 ? comment : undefined },
			}).unwrap();

			toast({ title: 'Statut mis à jour', status: 'success' });
			handleClose();
		} catch (error) {
			toast({ title: 'Erreur lors de la mise a jour du statut', status: 'error' });
		}
		// eslint-disable-next-line prettier/prettier
	}, [comment, deal.id, deal.opsPropertiesId, deal.productType, effectiveDate, files, handleClose, nbShares, needsDoc, nextStatus, toast, updateStatus, uploadShareAttestation, typePart]);

	useEffect(() => {
		if (isNone(deal) || (deal.productType !== ProductType.SCPI && deal.productType !== ProductType.PE)) return;
		if (deal.nbShare) setNbShares(deal.nbShare.toString());
	}, [deal]);

	return (
		<AlertDialog
			size="3xl"
			isOpen={isOpen}
			onClose={handleClose}
			header="Changement de statut du deal"
			body={
				<VStack align="start" w="100%">
					<VStack align="start" w="100%">
						<TableContainer w="100%">
							<Table size="sm">
								<Tbody>
									{[
										{ id: 'Produit', value: deal.productType },
										{ id: 'Client', value: deal.user.email },
										{ id: 'Statut actuel', value: deal.status },
										{ id: 'Nouveau statut', value: nextStatus ?? 'N/A' },
									].map(({ id, value }) => (
										<Tr key={id}>
											<Td>{id}</Td>
											<Td>{value}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>

						<Textarea
							mt="16px"
							w="100%"
							value={comment}
							onChange={(event) => setComment(event.target.value)}
							placeholder="Laisser un commentaire"
						/>

						{needsDoc && (
							<VStack align="start" mt="16px" w="100%">
								{(deal.productType === ProductType.PE || deal.productType === ProductType.SCPI) && (
									<VStack w="100%" spacing="0px" align="start">
										<FormLabel>Nombre de parts</FormLabel>
										<Input type="number" value={nbShares} onChange={(event) => setNbShares(event.target.value)} />
									</VStack>
								)}
								{deal.productType === ProductType.PE && (
									<VStack w="100%" spacing="0px" align="start">
										<FormLabel>Type de part</FormLabel>
										<Input value={typePart} onChange={(event) => setTypePart(event.target.value)} />
									</VStack>
								)}
								{deal.productType === ProductType.SCPI && (
									<VStack w="100%" spacing="0px" align="start">
										<FormLabel>Date d'effet</FormLabel>
										<DatePicker selected={effectiveDate} onChange={(d) => isNotNone(d) && setEffectiveDate(d)} />
									</VStack>
								)}
								<RequiredDocsUpload
									files={files}
									addFiles={(key, value) => {
										setFiles((prevFiles) => ({ ...prevFiles, [key]: { content: value, label: prevFiles[key].label } }));
									}}
								/>
							</VStack>
						)}

						<DealBoInfo deal={deal} />
					</VStack>
				</VStack>
			}
			footer={
				<HStack>
					<Button isLoading={isLoading || isLoadingUploadAirtableDocs} onClick={handleClose}>
						Annuler
					</Button>
					<Button
						isDisabled={isNone(nextStatus) || (needsDoc && !registeredStatusInfoFilled)}
						isLoading={isLoading || isLoadingUploadAirtableDocs}
						onClick={handleUpdateStatus}
						colorScheme="blue"
					>
						Valider
					</Button>
				</HStack>
			}
		/>
	);
};

export default NextStatusModal;
