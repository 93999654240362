import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Radio, RadioGroup, SimpleGrid } from '@chakra-ui/react';

import { CrossAssetBacktestBody } from 'services/cpm/cross-asset-backtest';
import { isNone } from 'utils/functions';

import CustomRisk from './CustomRisk';

const InvestmentPreferences: FC = () => {
	const { control } = useFormContext<CrossAssetBacktestBody>();

	return (
		<SimpleGrid columns={2} w="100%" gap="24px">
			<FormControl>
				<FormLabel size="sm">ESG ?</FormLabel>
				<Controller
					name="esg"
					control={control}
					render={({ field }) => (
						<RadioGroup
							{...field}
							onChange={(v) => {
								if (v === 'none') field.onChange(null);
								else field.onChange(+v);
							}}
							value={isNone(field.value) ? 'none' : field.value.toString()}
						>
							<HStack>
								<Radio value="none">Non</Radio>
								<Radio value="2">2°C</Radio>
								<Radio value="2.5">2.5°C</Radio>
							</HStack>
						</RadioGroup>
					)}
				/>
			</FormControl>
			<CustomRisk riskKey="risk" />
		</SimpleGrid>
	);
};

export default InvestmentPreferences;
