import React, { FC } from 'react';
import { Box, FormLabel, HStack, Text, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { GirardinData } from 'pages/cpm/product-propale/propaleData.type';

import AddCommentPropale from './common/AddCommentPropale';

type GirardinCardProps = {
	data: GirardinData;
	editData: React.Dispatch<React.SetStateAction<GirardinData[]>>;
};

export const GirardinCard: FC<GirardinCardProps> = ({ data, editData }) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack align="end">
			<Text fontStyle="italic" flex="1" fontSize="14px">
				La souscription Girardin sera directement créée avec le statut <b>PENDING</b> sans être affichée en tant que
				propale
			</Text>
			<Box flex="1">
				<FormLabel fontSize="sm">Montant</FormLabel>
				<NumberInputWithStepper
					step={1000}
					min={2500}
					value={data.initialDepositAmount + '€'}
					onChange={(amount) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, initialDepositAmount: +amount }].sort();
						});
					}}
				/>
			</Box>
		</HStack>
		{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
		<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
	</VStack>
);
