import { Route, Routes } from 'react-router-dom';

import ArtDetails from './ArtDetails';
import ArtList from './ArtList';

const PartnershipArtCopilot = () => (
	<Routes>
		<Route path="*" element={<ArtList />} />
		<Route path="/:id" element={<ArtDetails />} />
	</Routes>
);

export default PartnershipArtCopilot;
