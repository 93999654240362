import { FC } from 'react';
import { VStack } from '@chakra-ui/react';

import DirectInvestment from './DirectInvestment';
import InvestmentVehicle from './InvestmentVehicle';

const Portfolio: FC = () => (
	<VStack>
		<InvestmentVehicle />
		<DirectInvestment />
	</VStack>
);

export default Portfolio;
