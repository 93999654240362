import { Link } from 'react-router-dom';
import {
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	theme,
	Tr,
	VStack,
} from '@chakra-ui/react';

import { CryptoSubscription } from 'types/crypto.types';

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

const CryptoPartnerAdequationTable = ({
	subscriptionToInspect,
	setUserToTrack,
}: {
	subscriptionToInspect: CryptoSubscription[];
	setUserToTrack: (userEmail?: string) => void;
}) => (
	<VStack w="100%" align="start" spacing="24px">
		<TableContainer w="100%" bg="white" paddingTop="8px" borderRadius="8px" border="1px solid" borderColor="gray.300">
			<Table w="100%" variant="simple" size="md" __css={{ tableLayout: 'fixed' }}>
				<Thead w="100%">
					<Tr w="100%">
						<TableHeader w="300px">User</TableHeader>
					</Tr>
				</Thead>
				<Tbody w="100%">
					{subscriptionToInspect.map((sub) => (
						<Link to={`/ops/super/subscription/${sub?.id}?productType=CRYPTO`} key={sub?.id}>
							<Tr w="100%" bg="gray.white" onClick={() => setUserToTrack(sub?.user?.email)}>
								<TableValue _hover={{ bg: theme.colors.gray[100] }} cursor="pointer">
									<VStack align="start">
										<Text>{sub?.user?.email}</Text>
									</VStack>
								</TableValue>
							</Tr>
						</Link>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	</VStack>
);
export default CryptoPartnerAdequationTable;
