import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Box, HStack, VStack } from '@chakra-ui/react';

import { ClientIcon } from 'assets/icons';
import { DrawerButton } from 'pages/ops/super';
import ClientRouter from 'pages/ops/super/client';
import { BoPermission } from 'utils/permissions';

const SuperToolLayout = () => {
	const location = useLocation();

	return (
		<HStack h="calc(100vh - 65px)" w="100%" spacing="0px">
			<VStack
				h="100%"
				w="250px"
				py="24px"
				px="8px"
				alignSelf="start"
				spacing="4px"
				bg="white"
				borderRightWidth="1px"
				borderColor="grey.200"
			>
				<DrawerButton
					path="client"
					permission={BoPermission.CLIENT_READ}
					location={location}
					icon={<ClientIcon boxSize="24px" />}
				>
					Client
				</DrawerButton>
			</VStack>

			<Box h="100%" w="calc(100% - 250px)" px="32px" py="24px" bg="grey.50">
				<Outlet />
			</Box>
		</HStack>
	);
};

const SuperToolRouter = (): JSX.Element => (
	<Routes>
		<Route element={<SuperToolLayout />}>
			<Route path="client/*" element={<ClientRouter basePath="cpm" />} />
			<Route path="*" element={<Navigate to="/cpm/super/client" replace />} />
		</Route>
	</Routes>
);

export default SuperToolRouter;
